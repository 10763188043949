import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

i18n
  .use(initReactI18next)
  .use(Backend) // Load for translation files
  .use(LanguageDetector) // Detect for browser language
  .init({
    fallbackLng: "az", // Default language
    supportedLngs: ["az", "en", "ru"], // Supported languages
    detection: {
      order: ["localStorage", "navigator", "htmlTag"], // Order for detect language
      caches: ["localStorage"], // Selected language save to localstorage
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path translation files
    },
    interpolation: {
      escapeValue: false, // React already support XSS protect
    },
  });

// Detect and control browser language and apply exclusive logic
const userLanguage = i18n.language || navigator.language;

if (!["az", "en", "ru"].includes(userLanguage)) {
  if (userLanguage.startsWith("tr")) {
    const saved = localStorage.getItem("i18nextLng");
    if (saved === null) {
      i18n.changeLanguage("az");
    }
  } else {
    i18n.changeLanguage("en");
  }
}

export default i18n;
