import React, { useEffect, useRef, useState } from "react";
import * as ProfileService from "../Modules/Profile/Service/ProfileService";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";

const OtpForm = ({ onSubmit, phone }) => {
  const { t } = useTranslation();

  const [confirmOtp, setConfirmOtp] = useState({
    otp: "",
    extra_phone_number: phone,
  });
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    const inputs = inputRefs.current;

    const handleKeyDown = (e, index) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handleInput = (e, index) => {
      if (e.target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          document.getElementById("submit-button").focus();
        }
      }
    };

    const handleFocus = (e) => {
      e.target.select();
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, idx) => {
        input.value = digits[idx];
      });
      document.getElementById("submit-button").focus();
    };

    inputs.forEach((input, index) => {
      input.addEventListener("input", (e) => handleInput(e, index));
      input.addEventListener("keydown", (e) => handleKeyDown(e, index));
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputs?.forEach((input) => {
        input?.removeEventListener("input", handleInput);
        input?.removeEventListener("keydown", handleKeyDown);
        input?.removeEventListener("focus", handleFocus);
        input?.removeEventListener("paste", handlePaste);
      });
    };
  }, []);

  // Verify otp code
  const verifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await ProfileService.otpConfirmation(confirmOtp);
      onSubmit(true);
    } catch (err) {
      onSubmit(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 250,
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "white",
            borderRadius: "0.75rem",
          }}
        >
          <header style={{ marginBottom: "2rem" }}>
            <h1
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "0.25rem",
              }}
            >
              {t("otpModal.submitNumber")}
            </h1>
            <p
              style={{
                fontSize: "0.9375rem",
                color: "#64748b",
              }}
            >
              {t("otpModal.writeCode")}
            </p>
          </header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.75rem",
            }}
          >
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                style={{
                  width: "3.5rem",
                  height: "3.5rem",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontWeight: "800",
                  color: "#0f172a",
                  backgroundColor: "#f1f5f9",
                  border: "1px solid transparent",
                  borderRadius: "0.375rem",
                  padding: "1rem",
                  outline: "none",
                  appearance: "none",
                  transition: "background-color 0.15s, border-color 0.15s",
                }}
                maxLength="1"
                ref={(el) => (inputRefs.current[index] = el)}
                onFocus={(e) => (e.target.style.backgroundColor = "#fff")}
                onBlur={(e) => (e.target.style.backgroundColor = "#f1f5f9")}
                onChange={(e) =>
                  setConfirmOtp((prev) => ({
                    ...prev,
                    otp: String(prev.otp) + String(e.target.value),
                  }))
                }
              />
            ))}
          </div>
          <div
            style={{
              maxWidth: "16.25rem",
              margin: "1rem auto 0",
            }}
          >
            <button
              id="submit-button"
              style={{
                width: "100%",
                height: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 0,
                outline: 0,
                background: "#115edd",
                color: "#fff",
                borderRadius: "8px",
                transition: "0.3s ease linear",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#4f46e5")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#6366f1")}
              onClick={verifyOtp}
            >
              {t("otpModal.send")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
