import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SettingsPlanCard from "./SettingsPlanCard";
import * as HomeService from "../../../Home/Service/HomeService";
import Loading from "../../../../components/Loading";
import {PROFILE_TYPES} from "../../../../constants/constants";
import {LoginContext} from "../../../../utils/Auth";
import BuyPackModal from "../../../../components/BuyPackModal";
import {MainContext} from "../../../../utils/MainContext";
import {toastMsg} from "../../../../utils/swal";

const PlanTab = () => {
  const { user } = useContext(LoginContext);
  const { setOpenModal, setSelectedPack } = useContext(MainContext);
  const [userProfileType, setUserProfileType] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selected, setSelected] = useState(null);

  const getPlans = async () => {
    setLoading(true);
    try {
      const res = await HomeService.planAllList();
      const filtered = res.filter(
        (item) => item.profile_type === PROFILE_TYPES.personal
      );
      setPlans(filtered);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentPlan = plans.find(
      (item) => item.profile_type === user?.profile?.profile_type
    );
    setSelected(currentPlan);
    setUserProfileType(user?.profile?.profile_type);
  }, [user]);

  useEffect(() => {
    getPlans();
  }, []);

  const updatePlan = async () => {
    if (selected) {
      setOpenModal(true);
      setSelectedPack(selected);
    } else {
      toastMsg(t("settings.errors.selectPlan"), "#ff0000", "#000000");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="planTab">
      <div className="titles">
        <h4 className="title">{t("settings.plan")}</h4>
        <h4>{t("settings.myPlan")}</h4>
      </div>
      <div className="plans">
        {plans.map((item) => (
          <SettingsPlanCard
            key={item.id}
            selected={item.profile_type === selected?.profile_type}
            data={item}
            isOwnerPlan={item.profile_type === userProfileType}
            onClickCard={(plan) => {
              setSelected(plan);
            }}
          />
        ))}
      </div>
      {userProfileType !== PROFILE_TYPES.personal && (
        <>
          <button className="btn" onClick={updatePlan}>
            {t("settings.update")}
          </button>
          <BuyPackModal
            userId={user?.id}
            profile_type={selected?.profile_type}
          />
        </>
      )}
    </div>
  );
};

export default PlanTab;
