import React, {useEffect, useState} from "react";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import {getEmploymentType} from "../../../../utils/helper";
import dayjs from "dayjs";
import {confirmSwal} from "../../../../utils/swal";
import * as ProfileService from "../../Service/ProfileService";
import ExperienceImage from "./ExperienceImage";
import {useTranslation} from "react-i18next";

const ProfileEditExperience = ({
  onAdd,
  setProfileData,
  closeModal,
  profileData,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(profileData.experiences);
  }, [profileData.experiences]);

  const removeExperience = (item) => {
    confirmSwal(
      item.title,
      t("profileEditExperience.confirmDelete"),
      t("profileEditExperience.yes"),
      t("profileEditExperience.no"),
      () => {},
      async () => {
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "experience",
            id: item.id,
          });
          setProfileData((prev) => ({
            ...prev,
            experiences: res.data.experiences,
          }));
          setData((prev) => prev.filter((exp) => exp.id !== item.id));
        } catch (err) {
          console.log(err);
        }
      },
      t("profileEditExperience.completed"),
      t("profileEditExperience.cancel")
    );
  };

  const calcExperience = (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const totalMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    if (totalMonths >= 12) {
      return `${Math.floor(totalMonths / 12)} ${t(
        "profileEditExperience.year"
      )}`;
    } else {
      return `${totalMonths} ${t("profileEditExperience.month")}`;
    }
  };

  return (
    <div className="editExperience">
      <div
        className="profileExperience"
        style={{ maxHeight: 400, overflowY: "auto", padding: "0 16px" }}
      >
        {data?.length !== 0 &&
          data?.map((item) => (
            <div className="expCard" key={item.id}>
              <div className="top">
                <div className="expImg">
                  <ExperienceImage
                    char={item.company.slice(0, 1) || item.title.slice(0, 1)}
                  />
                </div>
                <div className="info">
                  <div className="editPosition">
                    <p className="title">{item.title}</p>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 16 }}
                    >
                      <button
                        className="removeBtn"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeExperience(item);
                        }}
                      >
                        {t("profileEditExperience.delete")}
                      </button>
                      <button
                        className="btnAdd"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          onAdd(item);
                        }}
                      >
                        {t("profileEditExperience.edit")}
                      </button>
                    </div>
                  </div>
                  <div className="subTitle">
                    <span>{item.company}</span>
                    <span className="dot">•</span>
                    <span>{getEmploymentType(item.employment_type, t)}</span>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <p className="dates">
                  {dayjs(item?.start_date).format("DD.MM.YYYY")} -{" "}
                  {item?.still_working_there
                    ? t("profileEditExperience.continue")
                    : dayjs(item?.end_date).format("DD.MM.YYYY")}
                  {item?.start_date &&
                    item?.end_date &&
                    !item?.still_working_there && (
                      <span>
                        {" "}
                        • {calcExperience(item?.start_date, item?.end_date)}
                      </span>
                    )}
                </p>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="btnGroup">
        <div className="addBtn">
          <button
            className="btnAdd"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onAdd();
            }}
          >
            <img src={addCircle} alt="" />
            {t("profileEditExperience.add")}
          </button>
        </div>
        <button
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("profileEditExperience.close")}
        </button>
      </div>
    </div>
  );
};

export default ProfileEditExperience;
