import React from "react";
import tickCircleBlueIcon from "../../../../assets/images/icons/tick-circle-blue.svg";
import {useTranslation} from "react-i18next";

const SettingsPlanCard = ({ data, selected, onClickCard, isOwnerPlan }) => {
  const { t } = useTranslation();
  const yearlyDiscount = () => {
    let discount = 0;
    const yearlyPrice = parseFloat(data.yearly_price);
    const monthlyPrice = parseFloat(data.monthly_price);
    discount = 100 - (yearlyPrice * 100) / (monthlyPrice * 12);
    return Math.floor(discount);
  };

  return (
    <div
      className={`settingsPlanCard ${selected && "selected"}`}
      onClick={() =>
        onClickCard({
          ...data,
          discount: yearlyDiscount(),
          priceNoDiscount: (data.monthly_price * 12).toFixed(2),
        })
      }
    >
      <div className="cardHead">
        <h6 className="planTitle">{data.title}</h6>
        <span className="badge">
          {t("settings.saveOnYear").replace("percent", yearlyDiscount())}
        </span>
      </div>
      <div className="cardBody">
        <p className="price">
          {data.monthly_price} {t("settings.azn")}
        </p>
      </div>
      <div className="cardFooter">
        <p className="planDesc">
          {isOwnerPlan
            ? `${t("settings.nextPayment")} 24 ${t("months.february")} 2025`
            : ""}
        </p>
        {isOwnerPlan && (
          <img src={tickCircleBlueIcon} alt="tick-circle-blue-icon" />
        )}
      </div>
    </div>
  );
};

export default SettingsPlanCard;
