import React from "react";

const CustomSwitch = ({ checked, onChange, disabled }) => {
  return (
    <label className="customSwitch">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <span className="slider"></span>
    </label>
  );
};

export default CustomSwitch;
