import React, {useState} from "react";
import DashboardHeader from "../../../components/DashboardHeader";
import MobileNavBar from "../../../components/MobileNavBar";
import SettingsSideBar from "./components/SettingsSideBar";
import AccountTab from "./components/AccountTab";
import {settingsTabsEnum} from "../../../constants/constants";
import PlanTab from "./components/PlanTab";
import PaymentsTab from "./components/PaymentsTab";
import LanguageTab from "./components/LanguageTab";
import PrivacyTab from "./components/PrivacyTab";
import DeactivateTab from "./components/DeactivateTab";
import DataTab from "./components/DataTab";
import AboutTab from "./components/AboutTab";
import SecurityTab from "./components/SecurityTab";
import settingsIcon from "../../../assets/images/icons/settings.svg";
import {useTranslation} from "react-i18next";
import SettingsModal from "./components/modals/SettingsModal";

const Settings = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(settingsTabsEnum.account);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    customComponent: null,
    isOpen: false,
  });

  const closeModal = () => {
    setModalData({
      title: "",
      customComponent: null,
      isOpen: false,
    });
  };

  const renderCurrentTab = () => {
    switch (activeTab) {
      case settingsTabsEnum.account:
        return (
          <AccountTab setModalData={setModalData} closeModal={closeModal} />
        );
      case settingsTabsEnum.security:
        return (
          <SecurityTab setModalData={setModalData} closeModal={closeModal} />
        );
      case settingsTabsEnum.plan:
        return <PlanTab />;
      case settingsTabsEnum.payments:
        return <PaymentsTab />;
      case settingsTabsEnum.language:
        return <LanguageTab />;
      case settingsTabsEnum.privacy:
        return <PrivacyTab />;
      case settingsTabsEnum.deactivate:
        return <DeactivateTab />;
      case settingsTabsEnum.data:
        return <DataTab />;
      case settingsTabsEnum.about:
        return <AboutTab />;
      default:
        return <AccountTab />;
    }
  };

  return (
    <div className="settingsPage">
      <DashboardHeader />
      <MobileNavBar />
      <div className="settingsMobileHeader">
        <img
          src={settingsIcon}
          alt="settings-icon"
          onClick={() => setIsOpen(!isOpen)}
        />
        <p>{t(`settings.${activeTab}`)}</p>
      </div>
      <div className="row">
        <div
          className={`overlaySide ${isOpen && "isActive"}`}
          onClick={() => setIsOpen(false)}
        ></div>
        <div className={`leftSide ${isOpen && "isOpen"}`}>
          <SettingsSideBar
            onClickTab={(tab) => {
              setActiveTab(tab);
              setIsOpen(false);
            }}
            activeTab={activeTab}
          />
        </div>
        <div className="rightSide">{renderCurrentTab()}</div>
      </div>
      {modalData.isOpen && (
        <SettingsModal closeModal={closeModal} modalData={modalData} />
      )}
    </div>
  );
};

export default Settings;
