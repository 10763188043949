import { useContext } from "react";

// Router
import { NavLink, useLocation } from "react-router-dom";

// Images
import profile from "../assets/images/icons/mobile-profile.svg";
import search from "../assets/images/icons/mobile-search-icon.svg";
import share from "../assets/images/icons/link-icon.svg";
import myBox from "../assets/images/icons/my-box.svg";

// Components
import SharedProfileModal from "./SharedProfileModal";

// Context
import { MainContext } from "../utils/MainContext";
import { LoginContext } from "../utils/Auth";

// Translation
import { useTranslation } from "react-i18next";

const MobileNavBar = () => {
  const pathname = useLocation().pathname;
  // Translation
  const { t } = useTranslation();

  // Global states
  const { user } = useContext(LoginContext);
  const {
    setSharedProfileModal,
    setDashboardPage,
    dashboardPage,
    setMySelfProfilView,
  } = useContext(MainContext);

  return (
    <div className="mobileNavBar">
      <nav className="navigation">
        <ul className="list">
          <li className="item">
            <NavLink
              to="/dashboard"
              onClick={() => {
                setDashboardPage("boxes");
                setMySelfProfilView(false);
              }}
            >
              <div className="icon">
                <img src={myBox} alt="box-icon" />
              </div>
              <span
                className={`itemName ${dashboardPage === "boxes" && "blue"}`}
              >
                {t("mobileNavBar.box")}
              </span>
            </NavLink>
          </li>
          <li className="item">
            <NavLink to="/search" onClick={() => setMySelfProfilView(false)}>
              <div className="icon">
                <img src={search} alt="search-icon" />
              </div>
              <span
                className={`itemName ${pathname.includes("search") && "blue"}`}
              >
                {t("mobileNavBar.search")}
              </span>
            </NavLink>
          </li>
          <li className="item" onClick={() => setSharedProfileModal(true)}>
            <div className="icon">
              <img src={share} alt="share-icon" />
            </div>
            <span className="itemName">{t("mobileNavBar.share")}</span>
          </li>
          <li className="item">
            <NavLink to={`/profile/${user?.profile?.username}`}>
              <div className="icon">
                <img src={profile} alt="profile-icon" />
              </div>
              <span
                className={`itemName ${
                  dashboardPage === "upgrade-pack" ||
                  pathname.includes("profile")
                    ? "blue"
                    : ""
                }`}
              >
                {t("mobileNavBar.profile")}
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <SharedProfileModal data={user} />
    </div>
  );
};

export default MobileNavBar;
