let $api;

$api = (service, params) => {
  const services = {
    // Additional libraries
    country_data_by_ip: "https://ipapi.co/json/",

    // For development
    image_url: process.env.REACT_APP_API_URL,

    // USER ENDPOINTS
    user_register: "/api/v1/user/register/",
    user_register_confirmation: "/api/v1/user/confirm-registration/",
    user_register_resend_otp: "/api/v1/user/register/resend-otp/",
    send_reset_password_otp: "/api/v1/user/send-reset-password-otp/",
    user_register_activate_user: "/api/v1/user/activate-user/",
    user_send_reset_password_otp: "/api/v1/user/send-reset-password-otp/",
    user_set_new_password: "/api/v1/user/set-new-password/",
    user_confirm_reset_password: "/api/v1/user/confirm-reset-password-otp/",
    user_change_password: "/api/v1/user/change-password/",
    user_update_multiple: "/api/v1/user/update/",
    user_update_single: "/api/v1/user/update/",
    user_get: "/api/v1/user/",
    user_login: "/api/v1/user/login/",
    user_get_profile: "/api/v1/user/profile/",
    user_update_profile: "/api/v1/user/profile/",
    user_refresh_token: "/api/v1/user/refresh/",

    // PROFILE ENDPOINTS
    profile_detail_by_username: "/api/v1/profile/{username}/",
    profile_update: "/api/v1/user/profile/",
    profile_detail_by_id: "/api/v1/profiles/id/{id}/",
    profile_detail_delete_by_id:
      "/api/v1/user/profile/delete/{field_name}/{id}",
    send_verify_extra_phone_number_otp:
      "/api/v1/profile/send-verify-extra-phone-number-otp/",
    confirm_verify_extra_phone_number_otp:
      "/api/v1/profile/confirm-verify-extra-phone-number-otp/",
    confirm_verify_extra_emails: "/api/v1/profile/verify-extra-email/{id}/",

    // SEARCH ENDPOINTS
    search_profiles: "/api/v1/profiles/?limit={limit}&page={page}&q={keyword}",
    save_search: "/api/v1/profile/save-search/",
    // PRE-REGISTER ENDPOINTS
    pre_register: "/api/v1/pre-register",

    // PRICING ENDPOINTS
    pricing: "/api/v1/plans",

    // SUBSCRIPTION
    subscribe_plan: "/api/v1/subscriptions",

    // Trial
    free_trial: "/api/v1/start-free-trial/",

    // ORDER BUCARD ENDPOINTS
    order_bucard_get_card_styles: "/api/v1/user/card-styles/",
    order_bucard_order_card: "/api/v1/user/order-card/",
    order_bucard_validate_card_order: "/api/v1/user/validate-order/",

    // CORE ENDPOINTS
    professions_all_list:
      "/api/v1/professions?limit={limit}&page={page}&name={name}",
    languages_all_list:
      "/api/v1/languages?name={name}&limit={limit}&offset={offset}",
    get_url_meta_data: "/api/v1/featured-links/fetch-metadata",
    skills_all_list: "/api/v1/skills?limit={limit}&page={page}&title={title}",
    keywords_all_list:
      "/api/v1/keywords?limit={limit}&page={page}&title={title}",

    // BOXES ENDPOINTS
    boxes_get_all: "/api/v1/boxes",
    boxes_create: "/api/v1/boxes",
    boxes_get_details: "/api/v1/boxes/{id}",
    boxes_delete: "/api/v1/boxes/{id}",
    boxes_add_profile: "/api/v1/boxes/{id}/add_profile",
    boxes_remove_profile: "/api/v1/boxes/{id}/remove_profile",
    boxes_edit: "/api/v1/boxes/{id}/update_box",
    // boxes_move: "/api/v1/boxes/{id}/add_profile",
    boxes_move: "/api/v1/boxes/move_between_boxes",
    // PLAN ENDPOINTS
    plan_get_all: "/api/v1/plans",
    google_map_develop: "AIzaSyB0R8fqVxNCWhVIEOYbuiRapr7aEuDapcI",
    google_map_production: "AIzaSyCWm091aE-ja-ypxO7v_CiCi0j5221p2zg",
    youtube_api_key: "AIzaSyDmUmomgpVJKMgs6xWf5E9B9hPc7EH7YEk",
  };
  if (typeof services[service] === "string") {
    services[service] = { path: String(services[service]) };
  }

  let url = services[service].path;
  if (params) {
    let query_params = {};
    let has_query_params = false;

    for (const [key, value] of Object.entries(params)) {
      let replaced = false;
      url = url.replaceAll("{" + key + "}", () => {
        replaced = true;
        return value;
      });
      if (!replaced) {
        has_query_params = true;
        query_params[key] = value;
      }
    }

    if (has_query_params) {
      let param = new URLSearchParams(query_params);
      url += "?" + param.toString();
    }
  }
  url = url.replaceAll("{lang}");

  return url;
};

export { $api };
