import React, {useState} from "react";
import rightArrow from "../../../../assets/images/icons/arrow-right.svg";
import {securityTabEnum} from "../../../../constants/constants";
import {useTranslation} from "react-i18next";
import {useSecurityTabList} from "../../../../constants/useSecurityTabList";
import ChangePasswordTab from "./ChangePasswordTab";
import ProfileStatusTab from "./ProfileStatusTab";

const SecurityTab = ({ setModalData, closeModal }) => {
  const { t } = useTranslation();

  const [activeSecurityTab, setActiveSecurityTab] = useState(null);

  const securityTabs = useSecurityTabList();

  const renderSecurityTab = () => {
    switch (activeSecurityTab) {
      case securityTabEnum.changeYourPassword:
        return <ChangePasswordTab />;
      case securityTabEnum.privateProfileStatus:
        return (
          <ProfileStatusTab
            setModalData={setModalData}
            closeModal={closeModal}
          />
        );
      default:
        return <ChangePasswordTab />;
    }
  };

  return (
    <div className="securityTab">
      {!activeSecurityTab && (
        <div className="securityTabHeader">
          <h2>{t("settings.security")}</h2>
          <p>{t("settings.tabDesc")}</p>
        </div>
      )}
      {!activeSecurityTab ? (
        <ul className="securityTabList">
          {securityTabs
            .filter((tab) => tab.visible)
            .map((item) => (
              <li
                className={`securityTabListItem ${
                  item.id === activeSecurityTab && "active"
                }`}
                key={item.id}
                onClick={() => setActiveSecurityTab(item.id)}
              >
                {item.title}
                <div className="icon">
                  <img src={rightArrow} alt="rightArrow" />
                </div>
              </li>
            ))}
        </ul>
      ) : (
        renderSecurityTab()
      )}
    </div>
  );
};

export default SecurityTab;
