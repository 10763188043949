import {useEffect, useState} from "react";
import axios from "axios";
import {$api} from "../api/api";

const useCountryCodeDetector = () => {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get($api("country_data_by_ip"));
        setCountryCode(response.data.country_code.toUpperCase());
      } catch (error) {
        console.error("Error on detect country code:", error);
        setCountryCode(null);
      }
    };

    fetchCountryCode();
  }, []);

  return countryCode;
};

export default useCountryCodeDetector;
