import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../../../utils/Auth";
import CreditCard from "./CreditCard";
import {useTranslation} from "react-i18next";
import exportIcon from "../../../../assets/images/icons/export-icon.svg";
import {paymentsHistoryList} from "../../../../db/payments";
import circlePlusIcon from "../../../../assets/images/icons/add-circle.svg";
import {toastMsg} from "../../../../utils/swal";

const PaymentsTab = () => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const [creditCardList, setCreditCardList] = useState([]);

  // Fake data
  useEffect(() => {
    if (user) {
      setCreditCardList([
        {
          id: 1,
          cardNumber: "xxxx xxxx xxxx 1234",
          expires: "10/25",
          cardOwner: user.full_name,
          address: user.profile.address,
          cardType: "VISA",
        },
        {
          id: 2,
          cardNumber: "xxxx xxxx xxxx 0000",
          expires: "07/26",
          cardOwner: user.full_name,
          address: user.profile.address,
          cardType: "MASTER",
        },
      ]);
    }
  }, [user]);

  return (
    <div className="paymentsTab">
      <div className="paymentsTabHead">
        <h2 className="title">{t("settings.payments")}</h2>
        <button
          className="btn"
          onClick={() => toastMsg(t("settings.temporarilyUnavailable"))}
        >
          <img src={circlePlusIcon} alt="circle-plus-icon" />
          <span>{t("settings.addNew")}</span>
        </button>
      </div>
      <div className="cards">
        {creditCardList.map((item) => (
          <CreditCard
            key={item.id}
            data={item}
            onDelete={() => toastMsg(t("settings.temporarilyUnavailable"))}
          />
        ))}
      </div>
      <div className="paymentHistory">
        <div className="historyHead">
          <h2>{t("settings.paymentHistory")}</h2>
          <button
            className="btn"
            onClick={() => toastMsg(t("settings.temporarilyUnavailable"))}
          >
            <span>{t("settings.export")}</span>
            <img src={exportIcon} alt="export-icon" />
          </button>
        </div>
        <div className="historyBody">
          <table>
            <thead>
              <tr>
                <th>{t("settings.order")}</th>
                <th>{t("settings.paymentDate")}</th>
                <th>{t("settings.amount")}</th>
                <th>{t("settings.status")}</th>
                <th>{t("settings.paymentMethod")}</th>
              </tr>
            </thead>
            <tbody>
              {paymentsHistoryList.map((item) => (
                <tr key={item.id}>
                  <td>{item.order}</td>
                  <td>{item.paymentDate}</td>
                  <td>{item.amount} AZN</td>
                  <td>
                    <span
                      className={`badge ${
                        item.status === "FAILED" && "failed"
                      }`}
                    >
                      {item.status === "SUCCESS"
                        ? t("settings.succesful")
                        : t("settings.failed")}
                    </span>
                  </td>
                  <td>{item.cardNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentsTab;
