import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const CompanyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className="companyPage">
      <h2>{t("companyPage.soon")}</h2>
      <p onClick={() => navigate(-1)}>{t("companyPage.goBack")}</p>
    </section>
  );
};

export default CompanyPage;
