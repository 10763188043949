import React from "react";
import visaCard from "../../../../assets/images/icons/visa-card.svg";
import masterCard from "../../../../assets/images/icons/master-card.svg";
import {useTranslation} from "react-i18next";

const CreditCard = ({ data, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="creditCard">
      <div className="icon">
        <img
          src={data.cardType === "VISA" ? visaCard : masterCard}
          alt="visa-card"
        />
      </div>
      <div className="cardInfo">
        <div className="info">
          <p className="title">{t("creditCard.cardNumber")}:</p>
          <p className="data">{data.cardNumber}</p>
        </div>
        <div className="info">
          <p className="title">{t("creditCard.expires")}:</p>
          <p className="data">{data.expires}</p>
        </div>
        <div className="info">
          <p className="title">{t("creditCard.cardOwner")}:</p>
          <p className="data">{data.cardOwner}</p>
        </div>
        <div className="info">
          <p className="title">{t("creditCard.billingAddress")}:</p>
          <p className="data">{data.address}</p>
        </div>
        <div className="buttonArea">
          <button className="btn" onClick={onDelete}>
            {t("creditCard.delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
