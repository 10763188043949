import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import CustomInput from "../../../../components/CustomInput";
import trashIcon from "../../../../assets/images/icons/trash.svg";
import plusCircle from "../../../../assets/images/icons/plus-circle.svg";
import {LoginContext} from "../../../../utils/Auth";
import {getSiteName} from "../../../../utils/helper";
import addSquare from "../../../../assets/images/icons/add-square.svg";
import PhoneEditModal from "./modals/PhoneEditModal";
import EmailEditModal from "./modals/EmailEditModal";
import SocialEditModal from "./modals/SocialEditModal";
import * as ProfileService from "../../../Profile/Service/ProfileService";
import {toastMsg} from "../../../../utils/swal";
import Swal from "sweetalert2";
import Loading from "../../../../components/Loading";

const ContactInformationsTab = ({ setModalData, closeModal }) => {
  const { t } = useTranslation();
  const { user, isFree } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    full_name: user?.full_name || "",
    username: user?.username || "",
  });

  const isSaveButtonActive =
    profileData.full_name === user?.full_name &&
    profileData.username === user?.username;

  const onSaveUserInfo = async (e) => {
    e.preventDefault();
    try {
      const body = {
        full_name: profileData?.full_name,
        username: profileData?.username,
      };
      await ProfileService.userEdit(body);
      closeModal();
      window.location.reload();
    } catch (err) {
      if (err?.response?.data?.username) {
        toastMsg(t("settings.errors.alreadyTaken"), "#ff0000", "#000000");
      }
    }
  };

  const removeItem = (type, item) => {
    Swal.fire({
      title: type === "phone" ? item?.phone_number : item.email,
      text: `${
        type === "phone" ? t("settings.phoneNumber") : t("settings.email")
      } ${t("settings.confirmDelete")}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("settings.yes"),
      cancelButtonText: t("settings.no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await ProfileService.deleteProfileDetail({
            field_name: type === "phone" ? "extra_phone_number" : "extra_email",
            id: item.id,
          });
          Swal.fire(
            t("settings.success.sucessful"),
            `${
              type === "phone" ? t("settings.phoneNumber") : t("settings.email")
            } ${t("settings.deleted")}`,
            "success"
          );
          setTimeout(() => {
            closeModal();
            window.location.reload();
          }, 1000);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire(
          t("settings.canceled"),
          `${type === "phone" ? t("settings.phone") : t("settings.mail")} ${t(
            "settings.onCancelDeletion"
          )}`,
          "error"
        );
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="contactInformationsTab">
      <div className="contactTabHeader">
        <h2>{t("settings.contactInfo")}</h2>
        <p>{t("settings.tabDesc")}</p>
      </div>
      <div className="contactBox userInfoInputs">
        <CustomInput
          label={t("settings.nameSurname")}
          placeholder={"Tarlan Alijanov"}
          value={profileData?.full_name}
          onChange={(value) =>
            setProfileData((prev) => ({
              ...prev,
              full_name: value,
            }))
          }
          errorMessage={
            (!profileData?.full_name?.trim() ||
              profileData.full_name?.trim()?.length < 3) &&
            t("settings.errors.fillRight")
          }
        />
        {!isFree && (
          <CustomInput
            label={t("settings.username")}
            placeholder={"alidjanoff"}
            value={profileData?.username}
            onChange={(value) =>
              setProfileData((prev) => ({
                ...prev,
                username: value,
              }))
            }
            errorMessage={
              (!profileData?.username?.trim() ||
                profileData.username?.trim()?.length < 3) &&
              t("settings.errors.fillRight")
            }
          />
        )}
      </div>
      <div className="saveButton" onClick={onSaveUserInfo}>
        <button
          className="btn"
          disabled={
            isSaveButtonActive ||
            !(
              profileData.username?.trim()?.length >= 3 &&
              profileData.full_name?.trim()?.length >= 3
            )
          }
        >
          {t("settings.saveBtn")}
        </button>
      </div>
      <div className="contactBox contacts">
        <div className="contactBoxTitle">
          <h3>
            {isFree ? t("settings.phoneNumber") : t("settings.phoneNumbers")}
          </h3>
          {!isFree && (
            <button
              className="addContactBtn"
              onClick={() => {
                setModalData({
                  title: t("settings.addNewPhone"),
                  isOpen: true,
                  customComponent: <PhoneEditModal closeModal={closeModal} />,
                });
              }}
            >
              <img src={plusCircle} alt="circle-plus" />
              <span>{t("settings.addNew")}</span>
            </button>
          )}
        </div>
        <div className="nums">
          <p className="title">{t("settings.primary")}</p>
          <ul className="numsList">
            <li className="numsListItem">
              <p className="phone">{user?.phone_number || "-"}</p>
            </li>
          </ul>
          {/* Below codes for design */}
        </div>
        {!isFree && (
          <div className="nums others">
            <p className="title">{t("settings.others")}</p>
            <ul className="numsList">
              {user?.profile?.extra_phone_numbers?.map((item) => (
                <li className="numsListItem" key={item.id}>
                  <div className="head">
                    <p className="phone">{item.phone_number}</p>
                    <p
                      className={`status ${!item.is_verified && "unverified"}`}
                    >
                      {item.is_verified
                        ? t("settings.verified")
                        : t("settings.unverified")}
                    </p>
                  </div>
                  <div className="foot">
                    <button
                      className={`changeBtn ${!item.is_verified && "disabled"}`}
                      onClick={() => {
                        toastMsg(t("settings.temporarilyUnavailable"));
                      }}
                      disabled={!item.is_verified}
                    >
                      {t("settings.makePrimary")}
                    </button>
                    <div
                      className="trashIcon"
                      onClick={() => removeItem("phone", item)}
                    >
                      <img src={trashIcon} alt="trash-icon" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {!isFree && (
        <div className="contactBox contacts">
          <div className="contactBoxTitle">
            <h3>{t("settings.emails")}</h3>
            <button
              className="addContactBtn"
              onClick={() => {
                setModalData({
                  title: t("settings.addNewEmail"),
                  isOpen: true,
                  customComponent: <EmailEditModal closeModal={closeModal} />,
                });
              }}
            >
              <img src={plusCircle} alt="circle-plus" />
              <span>{t("settings.addNew")}</span>
            </button>
          </div>
          <div className="nums">
            <p className="title">{t("settings.primary")}</p>
            <ul className="numsList">
              <li className="numsListItem">
                <p className="phone">{user?.profile?.email || "-"}</p>
              </li>
            </ul>
            {/* Above codes for design */}
          </div>
          <div className="nums others">
            <p className="title">{t("settings.others")}</p>
            <ul className="numsList">
              {user?.profile?.extra_emails?.map((item) => (
                <li className="numsListItem" key={item.id}>
                  <div className="head">
                    <p className="phone">{item.email}</p>
                    <p
                      className={`status ${!item.is_verified && "unverified"}`}
                    >
                      {item.is_verified
                        ? t("settings.verified")
                        : t("settings.unverified")}
                    </p>
                  </div>
                  <div className="foot">
                    <button
                      className={`changeBtn ${!item.is_verified && "disabled"}`}
                      onClick={() => {
                        toastMsg(t("settings.temporarilyUnavailable"));
                      }}
                      disabled={!item.is_verified}
                    >
                      {t("settings.makePrimary")}
                    </button>
                    <div
                      className="trashIcon"
                      onClick={() => removeItem("email", item)}
                    >
                      <img src={trashIcon} alt="trash-icon" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!isFree && (
        <div className="contactBox socials">
          <div className="socialsBoxTitle">
            <h3>{t("settings.socials")}</h3>
          </div>
          <div className="socialList">
            {user?.profile?.social_media?.map((item) => (
              <CustomInput
                key={item.id}
                label={getSiteName(item.url)}
                onChange={(value) => {}}
                disabled={true}
                value={item.url}
                placeholder="https://linkedin.com/in/alidjanoff"
              />
            ))}
          </div>
          <div className="addNewLink">
            <button
              onClick={() => {
                setModalData({
                  title: t("settings.addSocial"),
                  isOpen: true,
                  customComponent: <SocialEditModal closeModal={closeModal} />,
                });
              }}
            >
              <img src={addSquare} alt="plus-icon" />
              <span>{t("settings.addNew")}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactInformationsTab;
