import loaderGif from "../assets/images/loader/250x250.gif";

const Loading = () => {
  return (
    <div className="loading">
      <img src={loaderGif} alt="loader" />
    </div>
  );
};

export default Loading;
