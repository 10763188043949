import {useTranslation} from "react-i18next";
import {accountTabEnum} from "./constants";

export const useAccountTabList = () => {
  const { t } = useTranslation();

  return [
    {
      id: accountTabEnum.general,
      title: t("settings.general"),
      visible: true,
    },
    {
      id: accountTabEnum.contactInformations,
      title: t("settings.contactInfo"),
      visible: true,
    },
  ];
};
