import {yupResolver} from "@hookform/resolvers/yup";
import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {object, string} from "yup";
import logOutIcon from "../../../assets/images/icons/logout.svg";
import searchIcon from "../../../assets/images/icons/search-icon.svg";
import searchLogo from "../../../assets/images/logo-search.svg";
import MobileNavBar from "../../../components/MobileNavBar";
import {LoginContext} from "../../../utils/Auth";
import linkIcon from "../../../assets/images/icons/link-icon-white.svg";
import {useUserLogOut} from "../../Auth/Service/AuthService";
import {MainContext} from "../../../utils/MainContext";
import SharedProfileModal from "../../../components/SharedProfileModal";
import placeholders from "../../../db/searchPlaceholder";
import noUserImage from "../../../assets/images/icons/no-user-image.svg";
import {PROFILE_TYPES} from "../../Profile/Service/ProfileService";
import bIcon from "../../../assets/images/icons/b-icon.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import mobileLogo from "../../../assets/images/icons/bd-white-mobile-logo.svg";
import whiteMenuBars from "../../../assets/images/icons/white-menu-bars.svg";

const Search = () => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const { setSharedProfileModal, setMySelfProfilView, setDashboardPage } =
    useContext(MainContext);
  const isPro = user?.profile?.profile_type === PROFILE_TYPES.personal;
  const navigate = useNavigate();
  const userLogOut = useUserLogOut();
  const [openMenu, setOpenMenu] = useState(false);

  const searchSchema = object({
    searchValue: string()
      .required(t("search.errorMsg1"))
      .max(50, t("search.errorMsg2")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  const onSubmit = (data) => {
    navigate(`/search-result?filter=${data.searchValue}`);
  };

  const logOut = () => {
    userLogOut();
  };

  return (
    <main>
      <section className="searchPage">
        <div
          onClick={() => setOpenMenu(false)}
          className={`outsildeClickOverlay ${openMenu && "active"}`}
        ></div>
        <div className="sectionHeader">
          <div className="topSide">
            <div className="logo">
              <Link to="/dashboard">
                <img src={searchLogo} alt="logo" />
                <img className="mobile" src={mobileLogo} alt="logo" />
              </Link>
            </div>
            <div className="account">
              <div className="menuBars" onClick={() => setOpenMenu(!openMenu)}>
                <img src={whiteMenuBars} alt="menu-bars-icon" />
              </div>
              <LanguageSelector />
              <div
                className="shareIcon"
                onClick={() => setSharedProfileModal(true)}
              >
                <div className="icon">
                  <img src={linkIcon} alt="link-icon" />
                </div>
                <p className="shareInfo">{t("dashboardHeader.share")}</p>
              </div>
              <div
                className="profileBadge"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <div
                  key={user?.profile?.avatar}
                  className={`avatar ${isPro && "avatarPro"} ${
                    user?.profile?.avatar === null && "isDefault"
                  }`}
                >
                  {user?.profile?.avatar ? (
                    <img
                      src={
                        user?.profile?.avatar
                          ? user?.profile?.avatar
                          : user?.profile?.avatar
                      }
                      alt={user?.profile?.full_name}
                    />
                  ) : (
                    <img
                      src={bIcon}
                      alt="default-icon"
                      className="defaultIcon"
                    />
                  )}
                </div>
                <p className={"title"}>{user?.full_name}</p>
              </div>
              <ul className={openMenu ? "accountMenu open" : "accountMenu"}>
                <li className="accountMenuItem">
                  <Link
                    to={
                      user?.profile?.profile_type !== PROFILE_TYPES.free &&
                      `/profile/${user?.username}`
                    }
                    onClick={() => {
                      setMySelfProfilView(false);
                      setDashboardPage("upgrade-pack");
                    }}
                  >
                    <img
                      src={
                        user?.profile?.avatar
                          ? user?.profile?.avatar
                          : noUserImage
                      }
                      alt="medal-icon"
                      className="medalIcon"
                    />
                    {user?.full_name}
                  </Link>
                </li>
                <li className="accountMenuItem" onClick={logOut}>
                  <Link to="#">
                    <img src={logOutIcon} alt="logout-icon" />
                    {t("search.signOut")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottomSide">
            <h2 className="title">{t("search.find")}</h2>
          </div>
        </div>
        <div className="sectionBody">
          <form
            className={`searchForm ${errors.searchValue && "isError"}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <label htmlFor="bucardSearch">
              <img src={searchIcon} alt="search-icon" />
            </label>
            <input
              type="text"
              name="bucardSearch"
              id="bucardSearch"
              placeholder={placeholders[Math.floor(Math.random() * 7)].title}
              className="searchInput"
              {...register("searchValue")}
            />
            <button className="searchBtn">{t("search.btnValue")}</button>
          </form>
          <span className={`errorMessage ${errors.searchValue && "isError"}`}>
            {errors?.searchValue?.message}
          </span>
          <ul className="keywordList">
            {user?.profile?.last_ten_searches?.length !== 0 &&
              user?.profile?.last_ten_searches?.map((item, index) => (
                <li
                  className="keyword"
                  key={index}
                  onClick={() => navigate(`/search-result?filter=${item}`)}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>
        <MobileNavBar />
        <SharedProfileModal data={user} />
      </section>
    </main>
  );
};

export default Search;
