import ChangePlan from "./ChangePlan";
import Hero from "./Hero";
import Packets from "./Packets";

const Home = () => {
  return (
    <main>
      <Hero />
      <ChangePlan />
      <Packets />
    </main>
  );
};

export default Home;
