import {useTranslation} from "react-i18next";
import checkBoxIcon from "../../../assets/images/checkboxIcon.svg";
import whatIsBucard from "../../../assets/images/whatIsBucard.png";

const ChangePlan = () => {
  const { t } = useTranslation();

  return (
    <section className="changePlan" id="whatIsBucard">
      <div className="row">
        <div className="whatIsBucard">
          <div className="whatIsInfo">
            <h4>{t("changePlan.question")}</h4>
            <p>{t("changePlan.personalInfo2")}</p>
            <p>{t("changePlan.businessInfo2")}</p>
            <p className="infoIcon">
              <img src={checkBoxIcon} alt="check-box" />
              {t("changePlan.personalInfo1")}
            </p>
            <p className="infoIcon">
              <img src={checkBoxIcon} alt="check-box" />
              {t("changePlan.personalInfo3")}
            </p>
            <p className="infoIcon">
              <img src={checkBoxIcon} alt="check-box" />
              {t("changePlan.personalInfo4")}
            </p>
            <p className="infoIcon">
              <img src={checkBoxIcon} alt="check-box" />
              {t("changePlan.personalInfo6")}
            </p>
            <p className="infoIcon">
              <img src={checkBoxIcon} alt="check-box" />
              {t("changePlan.personalInfo7")}
            </p>
          </div>
          <div className="whatIsImg">
            <img src={whatIsBucard} alt="whatIsBucard" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePlan;
