import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const getSkills = async (payload) => {
  return await $axios.get($api("skills_all_list", payload));
};

export const getLanguages = async (payload) => {
  return await $axios.get($api("languages_all_list", payload));
};

export const getKeywords = async (payload) => {
  return await $axios.get($api("keywords_all_list", payload));
};
