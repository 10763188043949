import {yupResolver} from "@hookform/resolvers/yup";
import {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-number-input";
import {Link, useNavigate} from "react-router-dom";
import {object, string} from "yup";
import eyeSlash from "../../../assets/images/icons/eye-slash.svg";
import eye from "../../../assets/images/icons/eye.svg";
import Button from "../../../components/Button";
import ErrorLabel from "../../../components/ErrorLabel";
import Loading from "../../../components/Loading";
import SuccessLabel from "../../../components/SuccessLabel";
import {LoginContext} from "../../../utils/Auth";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const LoginForm = () => {
  // Translation
  const { t } = useTranslation();

  // Router
  const navigate = useNavigate();

  // Global states
  const {
    phone,
    setPhone,
    registerSuccessMsg,
    setRegisterSuccessMsg,
    setAllBoxes,
    setStep,
  } = useContext(MainContext);
  const { setIsUserIn } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // Local states
  const [showPassword, setShowPassword] = useState(false);
  const [phoneErrorVisible, setPhoneErrorVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Yup schema
  const userSchema = object({
    phone: string()
      .required(t("registerStepOne.errorMsg"))
      .min(9, t("registerStepOne.errorMsg"))
      .max(19, t("registerStepOne.errorMsg")),
    password: string()
      .required(t("login.errorMsg1"))
      .trim()
      .min(8, t("login.errorMsg2")),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const handleChange = (value) => {
    setPhone(value);
    setValue("phone", value, { shouldValidate: true });
  };

  // Form on submit
  const onSubmit = async (data) => {
    setLoading(true);

    const body = {
      phone_number: data.phone,
      password: data.password,
    };
    if (!isSubmitting) {
      setIsSubmitting(true);

      try {
        const res = await AuthService.userLogin(body);
        if (res.access) {
          setIsUserIn(true);
          setPhoneErrorVisible(false);
          navigate("/dashboard");
        }
      } catch (err) {
        if (err.response.data.detail) {
          setErrorMsg(t("login.errorMsg6"));
        }
      } finally {
        setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  // SuccessMgsREset
  useEffect(() => {
    if (registerSuccessMsg !== "") {
      const resetMsj = setTimeout(() => {
        setRegisterSuccessMsg("");
      }, 10000);

      return () => clearTimeout(resetMsj);
    }
  }, [registerSuccessMsg, setRegisterSuccessMsg]);

  return (
    <form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">{t("login.title")}</h2>
      <p className="question">
        {t("login.noAccount")}{" "}
        <Link to="/register" onClick={() => setStep(1)}>
          {t("login.signUp")}
        </Link>
      </p>
      {registerSuccessMsg && <SuccessLabel text={registerSuccessMsg} />}
      <div className="phoneBox">
        <label htmlFor="phone">{t("login.phone")} *</label>
        <PhoneInput
          international
          defaultCountry="AZ"
          value={phone}
          onChange={handleChange}
          limitMaxLength={19}
        />
        {errors.phone && phoneErrorVisible && (
          <span className="errorAlert">{errors.phone.message}</span>
        )}
      </div>
      <div className="inputBox">
        <label htmlFor="password">{t("login.pass")} *</label>
        <input
          type={showPassword ? "text" : "password"}
          className={errors.password ? "formInput err" : "formInput"}
          id="password"
          name="password"
          placeholder="********"
          {...register("password")}
        />
        {errors.password && (
          <span className="errorAlert">{errors.password.message}</span>
        )}
        <div
          className="eye"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img
            src={showPassword ? eyeSlash : eye}
            alt={showPassword ? "Hide password" : "Show password"}
          />
        </div>
        <div className="forgot">
          <Link to="/forgot-password">{t("login.forgotPass")}</Link>
        </div>
      </div>
      {errorMsg && <ErrorLabel text={errorMsg} />}
      <Button
        className="formBtn"
        disabled={errors.password && true}
        onClick={() => setPhoneErrorVisible(true)}
      >
        {t("login.btnValue")}
      </Button>
    </form>
  );
};

export default LoginForm;
