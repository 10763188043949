import React from "react";

const CustomInput = ({
  id,
  label,
  onChange,
  name,
  type,
  value,
  placeholder,
  disabled,
  maxLength,
  rightIcon,
  onClickRightIcon,
  defaultValue,
  errorMessage,
  hasImportant,
  checked,
}) => {
  return (
    <div className="customInput">
      {rightIcon ? (
        <div className="customInputWithIcon">
          {label && (
            <label
              htmlFor={id || ""}
              className={errorMessage ? "error-clr" : ""}
            >
              {label || ""}
              {hasImportant && <span> *</span>}
            </label>
          )}
          <div className="inputBox">
            <input
              className={
                errorMessage ? "error-clr error-border-clr error-bg-clr" : ""
              }
              onChange={(e) =>
                type === "checkbox"
                  ? onChange(e.target.checked)
                  : type === "file"
                  ? onChange(e.target.files[0])
                  : onChange(e.target.value)
              }
              name={name || ""}
              id={id || ""}
              type={type || "text"}
              value={value}
              placeholder={placeholder || ""}
              disabled={disabled}
              maxLength={maxLength}
              defaultValue={defaultValue}
              checked={checked}
            />
            <div
              className={`iconItem ${onClickRightIcon && "clickable"}`}
              onClick={onClickRightIcon ? onClickRightIcon : () => {}}
            >
              {rightIcon && <img src={rightIcon} alt="icon" />}
            </div>
          </div>
        </div>
      ) : (
        <>
          {label && (
            <label
              htmlFor={id || ""}
              className={errorMessage ? "error-clr" : ""}
            >
              {label || ""}
              {hasImportant && <span> *</span>}
            </label>
          )}
          <input
            className={
              errorMessage ? "error-clr error-border-clr error-bg-clr" : ""
            }
            onChange={(e) =>
              type === "checkbox"
                ? onChange(e.target.checked)
                : type === "file"
                ? onChange(e.target.files[0])
                : onChange(e.target.value)
            }
            name={name || ""}
            id={id || ""}
            type={type || "text"}
            value={value}
            placeholder={placeholder || ""}
            disabled={disabled}
            maxLength={maxLength}
            defaultValue={defaultValue}
            checked={checked}
          />
        </>
      )}
      {errorMessage && <span className="errorLabel">{errorMessage}</span>}
    </div>
  );
};

export default CustomInput;
