import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import MapComponent from "./MapComponent";
import * as ProfileService from "../../Service/ProfileService";
import locationImg from "../../../../assets/images/icons/location-add.svg";
import MapAutocomplete from "./MapAutocomplete";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import {useTranslation} from "react-i18next";

const initialErrorStates = {
  full_name: false,
  username: false,
  bio: false,
  profession: {
    name: false,
  },
};

const ProfileInfoEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLocation, setIsLocation] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    ...(profileData?.id ? { id: profileData.id } : {}),
    full_name: profileData?.full_name,
    username: profileData?.username,
    bio: profileData?.bio,
    address: profileData?.address,
    profession: profileData?.profession,
    location: {
      id: profileData?.location?.id,
      address: profileData?.location?.address,
      city: profileData?.location?.city,
      country: profileData?.location?.country,
      url: profileData?.location?.url,
      latitude: profileData?.location?.latitude,
      longitude: profileData?.location?.longitude,
    },
  });
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(initialErrorStates);

  const validateForm = (e) => {
    e.preventDefault();
    const errors = {
      full_name:
        !profileInfo?.full_name?.trim() ||
        profileInfo.full_name?.trim()?.length < 3,
      username: !profileInfo?.username?.trim(),
      bio: !profileInfo?.bio?.trim(),
      profession: {
        name: !profileInfo?.profession?.name?.trim(),
      },
    };
    setErrorMessages(errors);
    const isValid = Object.values(errors).every((value) => {
      if (typeof value === "object") {
        return Object.values(value).every((subValue) => subValue === false);
      }
      return value === false;
    });
    if (isValid) {
      onSubmit();
      setErrorMessages(initialErrorStates);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const body = {
        full_name: profileInfo?.full_name,
        username: profileInfo?.username,
        profile: {
          address: profileInfo?.address,
          bio: profileInfo?.bio,
          location: profileInfo?.location,
          profession: {
            name: profileInfo?.profession?.name,
            description: "Bucard",
          },
        },
      };
      if (profileData?.email) {
        body.email = profileData?.email;
        body.profile.email = profileData?.email;
      }
      const res = await ProfileService.userEdit(body);
      if (profileData?.username !== profileInfo?.username) {
        navigate(`/profile/${profileInfo?.username}`);
        window.location.reload();
      }
      setProfileData((prev) => ({
        ...prev,
        full_name: res?.data?.profile?.full_name,
        username: res?.data?.profile?.username,
        bio: res?.data?.profile?.bio,
        address: res?.data?.profile?.address,
        profession: res?.data?.profile?.profession,
        location: res?.data?.profile?.locations?.pop(),
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSelect = (data) => {
    const { formatted_address, city, country, latitude, longitude } = data;
    setProfileInfo((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        address: formatted_address,
        city,
        country,
        url: prev.url,
        latitude,
        longitude,
      },
      address: formatted_address,
    }));
    setProfileData((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        address: formatted_address,
        city,
        country,
        url: prev.url,
        latitude,
        longitude,
      },
      address: formatted_address,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileInfoEdit">
      {isLocation ? (
        <MapComponent
          onAddressSelect={handleAddressSelect}
          onClickBack={() => setIsLocation(false)}
          cordinates={{
            lat: profileData?.location?.latitude,
            lng: profileData?.location?.longitude,
          }}
          value={profileData?.address}
        />
      ) : (
        <form className="infoEditForm">
          <CustomInput
            label={t("profileInfoEdit.fullName")}
            hasImportant={true}
            placeholder={t("profileInfoEdit.fullNamePlace")}
            onChange={(value) => {
              const regex = /^[a-zA-ZÜİÖĞIƏÇŞüiöğıəçş\s]+$/;
              if (regex.test(value) || value === "") {
                setProfileInfo((prev) => ({
                  ...prev,
                  full_name: value,
                }));
              }
            }}
            value={profileInfo?.full_name}
            errorMessage={
              errorMessages?.full_name
                ? profileInfo?.full_name?.trim() === ""
                  ? t("profileInfoEdit.noEmpty")
                  : profileInfo?.full_name?.trim()?.length < 3
                  ? t("profileInfoEdit.minChar")
                  : ""
                : ""
            }
          />
          <CustomInput
            label={t("profileInfoEdit.username")}
            hasImportant={true}
            placeholder={t("profileInfoEdit.usernamePlace")}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                username: value,
              }));
            }}
            value={profileInfo?.username}
            errorMessage={
              errorMessages?.username && !profileInfo?.username?.trim()
                ? t("profileInfoEdit.noEmpty")
                : ""
            }
          />
          <CustomInput
            label={t("profileInfoEdit.workCompany")}
            hasImportant={true}
            placeholder={t("profileInfoEdit.workCompanyPlace")}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                bio: value,
              }));
            }}
            value={profileInfo?.bio}
            errorMessage={
              errorMessages?.bio && !profileInfo?.bio?.trim()
                ? t("profileInfoEdit.noEmpty")
                : ""
            }
          />
          <CustomInput
            label={t("profileInfoEdit.position")}
            hasImportant={true}
            placeholder={t("profileInfoEdit.positionPlace")}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                profession: {
                  ...prev.profession,
                  name: value,
                },
              }));
            }}
            value={profileInfo.profession?.name}
            errorMessage={
              errorMessages?.profession?.name &&
              !profileInfo.profession?.name?.trim()
                ? t("profileInfoEdit.noEmpty")
                : ""
            }
          />
          <MapAutocomplete
            hasImportant={true}
            onAddressSelected={(selected) => {
              if (selected !== null) {
                const {
                  formatted_address,
                  city,
                  country,
                  url,
                  latitude,
                  longitude,
                } = selected;
                setProfileInfo((prev) => ({
                  ...prev,
                  location: {
                    ...prev.location,
                    address: formatted_address,
                    city,
                    country,
                    url,
                    latitude,
                    longitude,
                  },
                  address: formatted_address,
                }));
              } else {
                setProfileInfo((prev) => ({
                  ...prev,
                  location: {},
                  address: "",
                }));
              }
            }}
            value={profileInfo?.address}
          />
          <div className="locationBtn" onClick={() => setIsLocation(true)}>
            <img src={locationImg} alt="location_add_icon" />
            <p>{t("profileInfoEdit.selectOnMap")}</p>
          </div>
          <div className="btnGroup">
            <button
              className="btn cancel"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              {t("profileInfoEdit.cancel")}
            </button>
            <button className="btn" onClick={validateForm}>
              {t("profileInfoEdit.save")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProfileInfoEdit;
