import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const getPlanAllList = async () => {
  return await $axios.get($api("plan_get_all"));
};

export const postFreeTrial = async (payload) => {
  return await $axios.post($api("free_trial"), payload);
};
