import React, {useContext, useState} from "react";
import {DEFAULT_IMAGES} from "../../../../constants/constants";
import {useTranslation} from "react-i18next";
import {LoginContext} from "../../../../utils/Auth";
import * as ProfileService from "../../../Profile/Service/ProfileService";
import Loading from "../../../../components/Loading";
import {toastMsg} from "../../../../utils/swal";

const GeneralTab = () => {
  const { t } = useTranslation();

  const { user, setUser, isFree } = useContext(LoginContext);

  const [loading, setLoading] = useState(false);
  const [editType, setEditType] = useState(null);
  const [preview, setPreview] = useState({
    profile: {
      avatar_b64: "",
      background_image_b64: "",
    },
  });

  const selectImage = async (e, type) => {
    try {
      const file = e.target.files[0];
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
      if (type === "profile") {
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            avatar_b64: base64,
          },
        }));
      } else {
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            background_image_b64: base64,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      profile:
        preview.profile.avatar_b64 && !preview.profile.background_image_b64
          ? {
              avatar_b64: preview.profile.avatar_b64,
            }
          : !preview.profile.avatar_b64 && preview.profile.background_image_b64
          ? {
              background_image_b64: preview.profile.background_image_b64,
            }
          : preview.profile.avatar_b64 && preview.profile.background_image_b64
          ? {
              avatar_b64: preview.profile.avatar_b64,
              background_image_b64: preview.profile.background_image_b64,
            }
          : null,
    };
    try {
      console.log("body", body);
      if (body?.profile !== null) {
        await ProfileService.userEdit(body);
        toastMsg(t("messages.success"), "#12DB63", "#000000");
        setUser((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            avatar: preview.profile.avatar_b64
              ? preview.profile.avatar_b64
              : user?.profile?.avatar,
            background_image: preview.profile.background_image_b64
              ? preview?.profile?.background_image_b64
              : user?.profile?.background_image,
          },
        }));
      } else {
        toastMsg(t("errors.systemError"), "#ED4611", "#000000");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="generalTab">
      <div className="generalTabHeader">
        <h2>{t("settings.generalInfo")}</h2>
        <p>{t("settings.tabDesc")}</p>
      </div>
      <div className="profileImage">
        <div className="imagesHeader">
          <h3>{t("settings.profPic")}</h3>
          <label htmlFor="profileImage">{t("settings.change")}</label>
          <input
            type="file"
            id="profileImage"
            style={{ display: "none" }}
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              setEditType("profile");
              selectImage(e, "profile");
            }}
          />
        </div>
        <div className="imagesBody">
          <div>
            <img
              src={
                preview?.profile?.avatar_b64 ||
                user?.profile?.avatar ||
                DEFAULT_IMAGES.avatar_b64
              }
              alt="profile-image"
            />
          </div>
        </div>
      </div>
      {!isFree && (
        <div className="backgroundImage">
          <div className="imagesHeader">
            <h3>{t("settings.bgPic")}</h3>
            <label htmlFor="backgroundImage">{t("settings.change")}</label>
            <input
              type="file"
              id="backgroundImage"
              style={{ display: "none" }}
              accept="image/png, image/jpg, image/jpeg"
              onChange={(e) => {
                setEditType("bg");
                selectImage(e, "bg");
              }}
            />
          </div>
          <div className="imagesBody">
            <div>
              <img
                src={
                  preview?.profile?.background_image_b64 ||
                  user?.profile?.background_image ||
                  DEFAULT_IMAGES.background_image_b64
                }
                alt="background-image"
              />
            </div>
          </div>
        </div>
      )}
      <div className="saveButton" onClick={saveChanges}>
        <button
          className="btn"
          disabled={
            !preview?.profile?.avatar_b64 &&
            !preview?.profile?.background_image_b64
          }
        >
          {t("settings.saveBtn")}
        </button>
      </div>
    </div>
  );
};

export default GeneralTab;
