import React, {useEffect, useState} from "react";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import Spinner from "../../../../components/Spinner";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";
import {toastMsg} from "../../../../utils/swal";
import CreatableSelect from "react-select/creatable";
import {useTranslation} from "react-i18next";

const ProfileSkillsEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    skills: profileData?.skills || [],
  });
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const getSkillsList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allSkills();
        setSkills(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getSkillsList();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData(res);
      setProfileInfo((prev) => ({
        ...prev,
        skills: res,
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeInp = async (input) => {
    setLoading(true);
    try {
      if (input.id) {
        await ProfileService.deleteProfileDetail({
          field_name: "skill",
          id: input.id,
        });
        const updated = profileInfo.skills.filter(
          (item) => item.id !== input.id
        );
        setProfileInfo((prev) => ({
          ...prev,
          skills: updated,
        }));
      } else {
        const updated = profileInfo.skills.filter(
          (item) => item.title.toLowerCase() !== input.title.toLowerCase()
        );
        setProfileInfo((prev) => ({
          ...prev,
          skills: updated,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async () => {
    setLoading(true);
    const missingKeywords = profileData.skills.filter((skill) => {
      return !profileInfo.skills.some(
        (item) => item.title.toLowerCase() === skill.title.toLowerCase()
      );
    });
    try {
      if (missingKeywords.length !== 0) {
        await ProfileService.profileEdit({
          skills: [...profileInfo.skills, ...missingKeywords],
        });
      }
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileSkillsEdit">
      <div className="infoSkillsEdit">
        <div className="editSkillsArea">
          {profileInfo.skills.map((skill) => (
            <button
              key={skill.id}
              className="skillsCaption"
              onClick={() => removeInp(skill)}
            >
              {skill.title}
              <span>x</span>
            </button>
          ))}
        </div>
        <div className="addSkillsArea">
          <CreatableSelect
            placeholder={t("profileSkillsEdit.write")}
            closeMenuOnSelect={true}
            controlShouldRenderValue={false}
            escapeClearsValue={true}
            hideSelectedOptions={true}
            options={skills}
            onChange={(selected) => {
              const existing = profileInfo.skills.some(
                (item) => item.id === selected.value
              );
              if (!existing) {
                setProfileInfo((prev) => ({
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      id: selected.value,
                      title: selected.label,
                    },
                  ],
                }));
              } else {
                toastMsg(
                  t("profileSkillsEdit.alreadyAdded"),
                  "#ffffff",
                  "#000000"
                );
              }
            }}
            isDisabled={profileInfo.skills.length >= 20}
            onCreateOption={(inputValue) => {
              const skillExist = profileInfo.skills.some(
                (item) => item.title.toLowerCase() === inputValue.toLowerCase()
              );
              if (!skillExist) {
                setProfileInfo((prev) => ({
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      title: inputValue,
                    },
                  ],
                }));
              } else {
                toastMsg(
                  t("profileSkillsEdit.alreadyAdded"),
                  "#ffffff",
                  "#000000"
                );
              }
            }}
          />
          <div className="infoSkills">
            <img src={infoIcon} alt="Info Icon" />
            {t("profileSkillsEdit.skillsLimit")}
          </div>
        </div>
        <div className="btnGroup">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              onCancel();
            }}
          >
            {t("profileSkillsEdit.cancel")}
          </button>
          <button className="btn" onClick={onSubmit}>
            {t("profileSkillsEdit.save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkillsEdit;
