import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {MainContext} from "../../../utils/MainContext";
import {useTranslation} from "react-i18next";

const SendLink = () => {
  const { t } = useTranslation();
  // Global states
  const { phone } = useContext(MainContext);

  // Router
  const navigate = useNavigate();

  // Local state
  const [count, setCount] = useState(30);

  // Timer
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      navigate("/");
    }
  }, [count, navigate]);

  return (
    <main>
      <section className="sendLink">
        <h3 className="infoText">
          {t("sendLink.sendText")}
          <span className="phoneNumber"> {phone}</span>
        </h3>
        <p className="request">{t("sendLink.checkPhone")}</p>
        <p className="request">
          <span className="timer">{count}</span>
          {t("sendLink.redirect")}
        </p>
        <Link to="/">{t("sendLink.goToHome")}</Link>
      </section>
    </main>
  );
};

export default SendLink;
