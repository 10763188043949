import React, {useEffect} from "react";
import {preLoaderAnim} from "./animation";
import {useTranslation} from "react-i18next";

const PreLoader = () => {
  const { t } = useTranslation();

  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="preloader">
      <div className="texts-container">
        <span>{t("preloader.personal")},</span>
        <span>{t("preloader.business")},</span>
        <span>{t("preloader.company")}.</span>
      </div>
    </div>
  );
};

export default PreLoader;
