import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import {useTranslation} from "react-i18next";
import Spinner from "../../../../components/Spinner";

const ProfileFeaturedAdd = ({ closeModal, profileData, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title,
    description: profileData?.description,
    url: profileData?.url,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await ProfileService.userEdit(profileInfo);
      setProfileData((prev) => ({
        ...prev,
        featured_links: [
          ...prev.featured_links,
          {
            id: res?.data?.id,
            title: res?.data?.title,
            description: res?.data?.description,
            url: res?.data?.url,
            image: null,
          },
        ],
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileFeaturedAdd">
      <form className="infoEditForm">
        <CustomInput
          label={t("profileFeaturedAdd.title")}
          placeholder={t("profileFeaturedAdd.titlePlace")}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              title: value,
            }));
          }}
          value={profileInfo.title}
        />
        <CustomInput
          label={t("profileFeaturedAdd.desc")}
          placeholder={t("profileFeaturedAdd.descPlace")}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              description: value,
            }));
          }}
          value={profileInfo.description}
        />
        <CustomInput
          label={t("profileFeaturedAdd.link")}
          placeholder={t("profileFeaturedAdd.linkPlace")}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              url: value,
            }));
          }}
          value={profileInfo.url}
        />
        <div className="btnGroup">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            {t("profileFeaturedAdd.cancel")}
          </button>
          <button className="btn" onClick={onSubmit}>
            {t("profileFeaturedAdd.save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileFeaturedAdd;
