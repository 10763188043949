import React, {useState} from "react";
import CustomInput from "../../../../../components/CustomInput";
import * as ProfileService from "../../../../Profile/Service/ProfileService";
import Spinner from "../../../../../components/Spinner";
import {SOCIAL_MEDIA_TYPES} from "../../../../../constants/constants";
import {v4 as uuidv4} from "uuid";
import {isValidUrl} from "../../../../../utils/helper";
import {toastMsg} from "../../../../../utils/swal";
import {useTranslation} from "react-i18next";

const SocialEditModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    social_media: [
      {
        id: uuidv4(),
        profile: "",
        url: "",
      },
    ],
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!isValidUrl(profileInfo?.social_media[0]?.url)) {
        toastMsg(t("socialEditModal.invalidUrl"), "#ff0000", "#000000");
        return;
      } else {
        await ProfileService.profileEdit({
          social_media: profileInfo.social_media.filter(
            (item) => item.url !== ""
          ),
        });
        closeModal();
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (value, input) => {
    const findedType = Object.values(SOCIAL_MEDIA_TYPES).find((item) =>
      String(value).toLowerCase().includes(item.toLowerCase())
    );

    const updated = profileInfo.social_media.map((item) => {
      if (input.id === item.id) {
        return {
          ...item,
          url: value,
          type: item.type || findedType,
        };
      } else {
        return item;
      }
    });

    setProfileInfo((prev) => ({
      ...prev,
      social_media: updated,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="socialEditModal">
      <form className="infoSocialForm">
        {profileInfo.social_media.map((item) => (
          <div className="socialInp" key={item.id}>
            <CustomInput
              placeholder={t("socialEditModal.enterLink")}
              onChange={(value) => handleChangeInput(value, item)}
              value={item.url}
            />
          </div>
        ))}
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("socialEditModal.cancel")}
        </button>
        <button disabled={false} className="btn" onClick={onSubmit}>
          {t("socialEditModal.save")}
        </button>
      </div>
    </div>
  );
};

export default SocialEditModal;
