import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const searchProfile = async (payload) => {
  return await $axios.get(
    $api("search_profiles", {
      keyword: payload.keyword,
      page: payload.page,
      limit: payload.limit,
    })
  );
};

export const postSaveSearch = async (payload) => {
  return await $axios.post($api("save_search"), payload);
};
