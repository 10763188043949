import {yupResolver} from "@hookform/resolvers/yup";
import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-number-input";
import {object, string} from "yup";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const StepOne = ({ setIsActivated }) => {
  // Local states
  const [phoneErrorVisible, setPhoneErrorVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Translation
  const { t } = useTranslation();

  // Global states
  const { setStep, setPhone, phone } = useContext(MainContext);

  const phoneSchema = object({
    phone: string()
      .required(t("registerStepOne.errorMsg"))
      .min(9, t("registerStepOne.errorMsg"))
      .max(19, t("registerStepOne.errorMsg")),
  });

  const [errorMsg, setErrorMsg] = useState("");
  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(phoneSchema),
  });

  const handleChange = (value) => {
    setPhone(value);
    setValue("phone", value, { shouldValidate: true });
  };

  const onSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const body = {
      phone_number: phone,
    };

    try {
      await AuthService.userRegister(body);
      setPhoneErrorVisible(false);
      setStep(2);
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || t("errors.systemError");
      setErrorMsg(errorMessage);

      if (err?.response?.data?.is_activated === "False") {
        setIsActivated(true);
        setStep(2);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {isSubmitting && <Loading />}
      <h2 className="formTitle">{t("registerStepOne.formTitle")}</h2>
      <div className="inputBox">
        <label htmlFor="phone">{t("registerStepOne.phone")} *</label>
        <PhoneInput
          international
          defaultCountry="AZ"
          value={phone || ""}
          onChange={handleChange}
        />
        {(errors.phone || errorMsg) && phoneErrorVisible && (
          <span className="errorAlert">
            {errors?.phone?.message || errorMsg}
          </span>
        )}
      </div>
      <Button
        className="formBtn"
        type="submit"
        onClick={() => setPhoneErrorVisible(true)}
      >
        {t("registerStepOne.btnValue")}
      </Button>
    </form>
  );
};

export default StepOne;
