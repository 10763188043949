import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CustomInput from "../../../../components/CustomInput";
import {Link} from "react-router-dom";
import * as SettingsService from "../../Service/SettingsService";
import {object, string} from "yup";
import {toastMsg} from "../../../../utils/swal";
import Loading from "../../../../components/Loading";

const width = window.innerWidth;
const initialState = {
  old_password: "",
  new_password: "",
  confirmPassword: "",
};

const ChangePasswordTab = () => {
  const { t } = useTranslation();

  const passwordSchema = object({
    old_password: string()
      .required(t("settings.errors.empty"))
      .trim(t("settings.errors.empty"))
      .min(8, t("settings.errors.minPassLimit")),
    new_password: string()
      .required(t("settings.errors.empty"))
      .trim(t("settings.errors.empty"))
      .min(8, t("settings.errors.minPassLimit")),
    confirmPassword: string()
      .required(t("settings.errors.empty"))
      .test(
        "passwords-match",
        t("settings.errors.confirmPass"),
        function (value) {
          return this.parent.new_password === value;
        }
      ),
  });

  const [loading, setLoading] = useState(false);
  const [passData, setPassData] = useState(initialState);
  const [errorMessages, setErrorMessages] = useState(initialState);

  const btnIsDisabled =
    passData.old_password.trim().length <= 7 ||
    passData.new_password.trim().length <= 7 ||
    passData.confirmPassword.trim().length <= 7 ||
    !passData.old_password.trim() ||
    !passData.new_password.trim() ||
    !passData.confirmPassword.trim();

  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await passwordSchema.validate(passData, {
        abortEarly: false,
      });
      const res = await SettingsService.changeYourPassword({
        old_password: passData.old_password,
        new_password: passData.new_password,
      });
      toastMsg(t("settings.success.password"), "#27e574", "#000000");
      setPassData(initialState);
    } catch (error) {
      if (error?.response?.data?.old_password?.detail) {
        toastMsg(t("settings.errors.old_password"), "#ff0000", "#000000");
      } else if (error?.message) {
        toastMsg(error?.message);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (name, value) => {
    setPassData((prev) => ({
      ...prev,
      [name]: value,
    }));
    try {
      await passwordSchema.validateAt(name, { ...passData, [name]: value });
      setErrorMessages((prev) => ({
        ...prev,
        [name]: "",
      }));
    } catch (error) {
      setErrorMessages((prev) => ({
        ...prev,
        [name]: error.message,
      }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="changePasswordTab">
      <form className="changePasswordForm">
        <div className="formHead">
          <div className="titles">
            <h4>{t("settings.changeYourPassword")}</h4>
            <p>{t("settings.passwordDesc")}</p>
          </div>
          {width > 768 && (
            <div className="saveButton">
              <button
                className="btn"
                disabled={btnIsDisabled}
                onClick={changePassword}
              >
                {t("settings.save")}
              </button>
            </div>
          )}
        </div>
        <div className="formBody">
          <CustomInput
            label={t("settings.currentPasswordDesc")}
            hasImportant={true}
            placeholder={t("settings.currentPassword")}
            onChange={(value) => handleChange("old_password", value)}
            value={passData.old_password}
            type={"password"}
            name="currentPassword"
            id="currentPassword"
            errorMessage={errorMessages.old_password}
          />
          <CustomInput
            label={t("settings.newPasswordDesc")}
            hasImportant={true}
            placeholder={t("settings.newPassword")}
            onChange={(value) => handleChange("new_password", value)}
            value={passData.new_password}
            type={"password"}
            name="newPassword"
            id="newPassword"
            errorMessage={errorMessages.new_password}
          />
          <CustomInput
            label={t("settings.retypePasswordDesc")}
            hasImportant={true}
            placeholder={t("settings.retypePassword")}
            onChange={(value) => handleChange("confirmPassword", value)}
            value={passData.confirmPassword}
            type={"password"}
            name="repeatNewPassword"
            id="repeatNewPassword"
            errorMessage={errorMessages.confirmPassword}
          />
        </div>
      </form>
      <Link to="/forgot-password">{t("settings.forgotPassword")}</Link>
      {width < 768 && (
        <div className="saveButton">
          <button
            className="btn"
            disabled={btnIsDisabled}
            onClick={changePassword}
          >
            {t("settings.save")}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordTab;
