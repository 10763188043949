import {yupResolver} from "@hookform/resolvers/yup";
import {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-number-input";
import {object, string} from "yup";
import Loading from "../../../components/Loading";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const ForgotForm = ({ setIsEmail }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setPhone, phone, setForgotStep } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [phoneErrorVisible, setPhoneErrorVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const phoneSchema = object({
    phone: string()
      .required(t("registerStepOne.errorMsg"))
      .min(9, t("registerStepOne.errorMsg"))
      .max(19, t("registerStepOne.errorMsg")),
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(phoneSchema),
  });

  const handleChange = (value) => {
    setPhone(value);
    setValue("phone", value, { shouldValidate: true });
  };

  const onSubmit = async () => {
    const body = {
      phone_number: phone,
    };
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        const res = await AuthService.resetPassword(body);
        if (res?.email) {
          setIsEmail(res?.email);
        }
        setPhoneErrorVisible(false);
        setForgotStep(2);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">{t("forgotPassword.title")}</h2>
      <p className="question">{t("forgotPassword.info")}</p>
      <div className="inputBox">
        <label htmlFor="phone">{t("forgotPassword.phone")} *</label>
        <PhoneInput
          international
          defaultCountry="AZ"
          value={phone}
          onChange={handleChange}
        />
        {errors.phone && phoneErrorVisible && (
          <span className="errorAlert">{errors.phone.message}</span>
        )}
      </div>
      <button className="formBtn" onClick={() => setPhoneErrorVisible(true)}>
        {t("forgotPassword.btnValue")}
      </button>
    </form>
  );
};

export default ForgotForm;
