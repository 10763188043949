import { useContext, useEffect, useState } from "react";

// Router
import { Link } from "react-router-dom";

// Images
import addIcon from "../assets/images/icons/add-circle.svg";
import closeModal from "../assets/images/icons/close-modal.svg";
import bIcon from "../assets/images/icons/b-icon.svg";

// Components
import AddNewBoxModal from "./AddNewBoxModal";

// Context
import { MainContext } from "../utils/MainContext";

// Translation
import { useTranslation } from "react-i18next";
import { toastMsg } from "../utils/swal";

import { PROFILE_TYPES } from "../constants/constants";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const SearchToBoxModal = ({ data }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const {
    searchToBoxModalOpen,
    setSearchToBoxModalOpen,
    setAddBoxOpen,
    addBoxOpen,
  } = useContext(MainContext);

  // Local states
  const [boxes, setBoxes] = useState([]);
  const [selectedBox, setSelectedBox] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const isPro = data?.profile_type === PROFILE_TYPES.personal;
  const isBusiness = data?.profile_type === PROFILE_TYPES.business;

  useEffect(() => {
    const getBoxList = async () => {
      try {
        const res = await DashboardService.getBoxesList();
        setBoxes(res);
      } catch (err) {
        console.log(err);
      }
    };
    if (!addBoxOpen) {
      getBoxList();
    }
  }, [addBoxOpen]);

  const addNewPeopleToBox = async () => {
    const body = { profile: data?.id };
    try {
      await DashboardService.addProfileToBox(selectedBox?.id, body);
      setSelectedBox({});
      setSearchToBoxModalOpen(false);
      toastMsg(
        `${t("searchToBoxModal.selectedUser")} ${selectedBox.title} ${t(
          "searchToBoxModal.addedToBox"
        )}`,
        "#fff",
        "#000"
      );
    } catch (err) {
      setErrorMsg(err.response.data.errors);
    } finally {
      sessionStorage.removeItem("addProfileToBoxUsername");
      window.location.reload();
    }
  };

  return (
    <div
      className={
        searchToBoxModalOpen ? "searchToBoxModal active" : "searchToBoxModal"
      }
    >
      <div
        className="modalOverlays"
        onClick={() => setSearchToBoxModalOpen(false)}
      ></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="selectedUser">
          <div className="addUserCard ">
            <div className="left">
              <div
                className={`avatar ${isPro && "avatarPro"} ${
                  data?.avatar === null && "isDefault"
                }`}
              >
                {data?.avatar ? (
                  <img
                    src={data?.avatar ? data?.avatar : data?.avatar}
                    alt={data.full_name}
                  />
                ) : (
                  <img src={bIcon} alt="default-icon" className="defaultIcon" />
                )}
                {isPro && (
                  <span
                    className={`badge ${
                      data?.avatar === null && "defaultBadgePosition"
                    }`}
                  >
                    {t("searchToBoxModal.pro")}
                  </span>
                )}
              </div>
              <div className="userInfo">
                <p className="fullName">{data?.full_name}</p>
                <Link className="phone" to={`tel:${data?.phone_number}`}>
                  {data?.phone_number}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="modalTitle">{t("searchToBoxModal.title")}</p>
        <div className="createBoxBtn">
          <div className="addIcon">
            <img src={addIcon} alt="add-icon" />
          </div>
          <p className="text" onClick={() => setAddBoxOpen(true)}>
            {t("searchToBoxModal.create")}
          </p>
        </div>
        <ul className="boxList">
          {boxes.map((box) => (
            <li
              className={
                selectedBox.id === box.id ? "boxCard selected" : "boxCard"
              }
              key={box.id}
              onClick={() => {
                setSelectedBox(box);
              }}
            >
              <div className="boxImg">
                <p>{box.icon}</p>
              </div>
              <p className="boxName">{box.title}</p>
            </li>
          ))}
        </ul>
        {errorMsg && <span className="errorMsg">{errorMsg}</span>}
        <button className="addBtn" onClick={addNewPeopleToBox}>
          {t("searchToBoxModal.add")}
        </button>
      </div>
      <div
        className="closeModal"
        onClick={() => setSearchToBoxModalOpen(false)}
      >
        <img src={closeModal} alt="close-icon" />
        <span>{t("searchToBoxModal.close")}</span>
      </div>
      <AddNewBoxModal />
    </div>
  );
};

export default SearchToBoxModal;
