import {useContext} from "react";
import {Link} from "react-router-dom";
import {MainContext} from "../../../utils/MainContext";
import {useTranslation} from "react-i18next";

const UserGuide = () => {
  const { t } = useTranslation();
  const { setDashboardPage } = useContext(MainContext);

  return (
    <div className="userGuide">
      <h2 className="title">{t("userGuide.soon")}</h2>
      <Link to="/dashboard" onClick={() => setDashboardPage("boxes")}>
        {t("userGuide.goBack")}
      </Link>
    </div>
  );
};

export default UserGuide;
