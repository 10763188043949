import {useTranslation} from "react-i18next";
import {PROFILE_TYPES, securityTabEnum} from "./constants";
import {useContext, useEffect, useState} from "react";
import {LoginContext} from "../utils/Auth";

export const useSecurityTabList = () => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const [isFreeUser, setIsFreeUser] = useState(false);

  useEffect(() => {
    setIsFreeUser(user?.profile?.profile_type === PROFILE_TYPES.free);
  }, [user]);

  return [
    {
      id: securityTabEnum.changeYourPassword,
      title: t("settings.changeYourPassword"),
      visible: true,
    },
    {
      id: securityTabEnum.privateProfileStatus,
      title: t("settings.profileStatus"),
      visible: isFreeUser ? false : true,
    },
  ];
};
