import React, {useState} from "react";
import * as ProfileService from "../../../../Profile/Service/ProfileService";
import Spinner from "../../../../../components/Spinner";
import PhoneInput from "react-phone-number-input";
import {useTranslation} from "react-i18next";
import {toastMsg} from "../../../../../utils/swal";
import useCountryCodeDetector from "../../../../../hooks/useCountryCodeDetector";

const PhoneEditModal = ({ profileData, closeModal }) => {
  const { t } = useTranslation();
  const countryCode = useCountryCodeDetector();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_phone_number: profileData?.extra_phone_numbers || [],
    phone_number: profileData?.phone_number || [],
  });
  const [newPhoneNumber, setNewPhoneNumber] = useState({
    phone_number: "",
    isVerified: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await ProfileService.profileEdit(profileInfo);
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const phoneNumber = value?.trim();
    setNewPhoneNumber({ phone_number: phoneNumber });
  };

  const verifyPhone = async (e, phone) => {
    setLoading(true);
    e.preventDefault();
    const body = {
      extra_phone_number: phone ? phone : newPhoneNumber.phone_number,
    };
    try {
      if (profileInfo?.phone_number !== newPhoneNumber?.phone_number) {
        if (body.extra_phone_number !== 0 || phone) {
          if (!phone) {
            await ProfileService.profileEdit({
              extra_phone_number: [
                phone ? { phone_number: phone } : newPhoneNumber,
              ],
            });
          } else {
            setNewPhoneNumber({ phone_number: phone });
          }
          window.location.reload();
        }
      } else {
        toastMsg(t("phoneEditModal.alreadyAdded"), "#ffa700", "#000000");
        setNewPhoneNumber({
          phone_number: "",
          isVerified: false,
        });
      }
    } catch (error) {
      if (error?.response?.data?.extra_phone_number?.includes("exists")) {
        toastMsg(t("phoneEditModal.alreadyAdded"), "#ffa700", "#000000");
        setNewPhoneNumber({
          phone_number: "",
          isVerified: false,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="phoneEditModal">
      <form onSubmit={(e) => e.preventDefault()} className="infoPhoneForm">
        <div className="phoneBox">
          <label htmlFor="phone">{t("login.phone")} *</label>
          <PhoneInput
            international
            defaultCountry={countryCode || "AZ"}
            value={newPhoneNumber?.phone_number}
            onChange={handleChange}
            limitMaxLength={19}
          />
        </div>
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("phoneEditModal.cancel")}
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={newPhoneNumber.isVerified ? onSubmit : verifyPhone}
          disabled={errorMessage !== ""}
        >
          {t("phoneEditModal.save")}
        </button>
      </div>
    </div>
  );
};

export default PhoneEditModal;
