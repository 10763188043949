import React, {useEffect, useState} from "react";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import lngIcon from "../../../../assets/images/icons/world-language-icon.svg";
import Spinner from "../../../../components/Spinner";
import {toastMsg} from "../../../../utils/swal";
import Select from "react-select";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";
import {useTranslation} from "react-i18next";

const ProfileLanguageEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    languages: profileData?.languages || [],
  });
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const getLanguagesList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allLanguages();
        setLanguages(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getLanguagesList();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData(res);
      setProfileInfo((prev) => ({
        ...prev,
        languages: res,
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeLanguage = async (lng) => {
    setLoading(true);
    try {
      await ProfileService.deleteProfileDetail({
        field_name: "language",
        id: lng.id,
      });
      const updatedList = profileInfo?.languages?.filter(
        (item) => item.id !== lng.id
      );
      setProfileData((prev) => ({
        ...prev,
        languages: updatedList,
      }));
      setProfileInfo((prev) => ({
        ...prev,
        languages: updatedList,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileLangEdit">
      <div className="infoLangEdit">
        <div className="editSkillsArea">
          {profileInfo.languages.map((language) => (
            <button key={language.id} className="skillsCaption">
              <img
                src={
                  `https://flagsapi.com/${
                    language?.code?.toUpperCase() === "EN"
                      ? "GB"
                      : language?.code?.toUpperCase()
                  }/flat/64.png` || lngIcon
                }
                alt="lng_icon"
              />
              {language.name}
              <span onClick={() => removeLanguage(language)}>x</span>
            </button>
          ))}
        </div>
        <div className="addSkillsArea">
          <Select
            placeholder={t("profileLangEdit.select")}
            closeMenuOnSelect={true}
            escapeClearsValue={true}
            options={languages}
            onChange={(selected) => {
              const existing = profileInfo.languages.find(
                (item) => item.id === selected.value
              );
              if (existing) {
                toastMsg(
                  t("profileLangEdit.alreadyAdded"),
                  "#ffffff",
                  "#000000"
                );
              } else {
                setProfileInfo((prev) => ({
                  ...prev,
                  languages: [
                    ...prev.languages,
                    {
                      id: selected.value,
                      code: selected.code,
                      name: selected.label,
                    },
                  ],
                }));
              }
            }}
            isDisabled={profileInfo.languages.length >= 20}
          />
          <div className="infoSkills">
            <img src={infoIcon} alt="Info Icon" />
            {t("profileLangEdit.lngLimit")}
          </div>
        </div>
        <div className="btnGroup">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            {t("profileLangEdit.cancel")}
          </button>
          <button className="btn" onClick={onSubmit}>
            {t("profileLangEdit.save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileLanguageEdit;
