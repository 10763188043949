import React from "react";
import {Link, useParams} from "react-router-dom";
import {paymentInfoTypes} from "../../../constants/constants";
import {useTranslation} from "react-i18next";

const PaymentInfo = () => {
  const { t } = useTranslation();
  const { type } = useParams();

  return (
    <section className="paymentInfo">
      <div className="container">
        <div className="row">
          {type === paymentInfoTypes.success ? (
            <div className="infoCard">
              <h2 className="success">{t("paymentInfo.completed")}</h2>
              <p>
                {t("paymentInfo.tryToEnter")}{" "}
                <Link to="/login">{t("paymentInfo.enter")}</Link>
              </p>
            </div>
          ) : type === paymentInfoTypes.cancel ? (
            <div className="infoCard">
              <h2 className="cancel">{t("paymentInfo.canceled")}</h2>
              <p>
                <Link to="/">{t("paymentInfo.homePage")}</Link>
              </p>
            </div>
          ) : (
            <div className="infoCard">
              <h2 className="decline">{t("paymentInfo.failed")}</h2>
              <p>
                <Link to="/">{t("paymentInfo.homePage")}</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentInfo;
