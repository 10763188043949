import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate, useParams } from "react-router-dom";
import PenIcon from "../../../assets/images/icons/edit-2.svg";
import playIcon from "../../../assets/images/icons/play-icon.svg";
import introVideoBg from "../../../assets/images/intro-video-bg.svg";
import DashboardHeader from "../../../components/DashboardHeader";
import Loading from "../../../components/Loading";
import MobileNavBar from "../../../components/MobileNavBar";
import SharedProfileModal from "../../../components/SharedProfileModal";
import SideBar from "../../../components/SideBar";
import { PROFILE_TYPES } from "../../../constants/constants";
import { LoginContext } from "../../../utils/Auth";
import { generateYouTubeEmbedUrlId } from "../../../utils/helper";
import { MainContext } from "../../../utils/MainContext";
import * as ProfileService from "../Service/ProfileService";
import ProfileAbout from "./components/ProfileAbout";
import ProfileAboutEdit from "./components/ProfileAboutEdit";
import ProfileBackgroundEdit from "./components/ProfileBackgroundEdit";
import ProfileCreateExp from "./components/ProfileCreateExp";
import ProfileEditExperience from "./components/ProfileEditExperience";
import ProfileEditImage from "./components/ProfileEditImage";
import ProfileEditModal from "./components/ProfileEditModal";
import ProfileEmailEdit from "./components/ProfileEmailEdit";
import ProfileExperience from "./components/ProfileExperience";
import ProfileFeaturedAdd from "./components/ProfileFeaturedAdd";
import ProfileFeaturedEdit from "./components/ProfileFeaturedEdit";
import ProfileFeaturedSection from "./components/ProfileFeaturedSection";
import ProfileHero from "./components/ProfileHero";
import ProfileInfoEdit from "./components/ProfileInfoEdit";
import ProfileKeywordEdit from "./components/ProfileKeywordEdit";
import ProfileKeywords from "./components/ProfileKeywords";
import ProfileLanguageEdit from "./components/ProfileLanguageEdit";
import ProfileLanguages from "./components/ProfileLanguages";
import ProfilePhoneNumbersEdit from "./components/ProfilePhoneNumbersEdit";
import ProfileSection from "./components/ProfileSection";
import ProfileSkills from "./components/ProfileSkills";
import ProfileSkillsEdit from "./components/ProfileSkillsEdit";
import ProfileSocialEdit from "./components/ProfileSocialEdit";
import ProfileSocialMedia from "./components/ProfileSocialMedia";
import ProfileVideoEdit from "./components/ProfileVideoEdit";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, isUserIn } = useContext(LoginContext);
  const { setDashboardPage } = useContext(MainContext);
  const { username } = useParams();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    customComponent: null,
    isOpen: false,
  });

  const isOwner = user && user?.username === username && isUserIn;
  const isProUser = profileData?.profile_type !== PROFILE_TYPES.free;

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      try {
        const res = await ProfileService.profileFindByUsername(username);
        setProfileData(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getProfileData();
  }, [username]);

  const closeModal = () => {
    setModalData({
      title: "",
      customComponent: null,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (modalData.isOpen) {
      document.body.style = "overflow: hidden;";
    } else {
      document.body.style = "overflow: auto;";
    }
  }, [modalData.isOpen]);

  const video_url = profileData?.intro_videos?.find(
    (item) => item
  )?.intro_video_url;

  return (
    <>
      <DashboardHeader />
      <section className="profile">
        {loading && <Loading />}
        {isUserIn && <SideBar />}
        <div className="container">
          <div className="row">
            <ProfileHero
              isProUser={isProUser}
              isOwner={isOwner}
              profileData={profileData}
              onEditProfilePhoto={() =>
                setModalData({
                  title: t("profileHero.managePicture"),
                  customComponent: (
                    <ProfileEditImage
                      closeModal={closeModal}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  ),
                  isOpen: true,
                })
              }
              onEditBackgroundPhoto={() =>
                setModalData({
                  title: t("profileHero.manageBg"),
                  customComponent: (
                    <ProfileBackgroundEdit
                      closeModal={closeModal}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  ),
                  isOpen: true,
                })
              }
              onEditProfileInfo={() =>
                setModalData({
                  title: t("profileHero.managePersonalInfo"),
                  customComponent: (
                    <ProfileInfoEdit
                      closeModal={closeModal}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  ),
                  isOpen: true,
                })
              }
              onEditPhoneNumbers={() =>
                setModalData({
                  title: t("profileHero.manageContactInfo"),
                  customComponent: (
                    <ProfilePhoneNumbersEdit
                      closeModal={closeModal}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  ),
                  isOpen: true,
                })
              }
              onEditEmails={() =>
                setModalData({
                  title: t("profileHero.manageEmail"),
                  customComponent: (
                    <ProfileEmailEdit
                      closeModal={closeModal}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  ),
                  isOpen: true,
                })
              }
              showProfileInfoEdit={isOwner}
              showPhoneNumbersEdit={isOwner && isProUser}
              showEmailsEdit={isOwner}
            />
          </div>
          <div
            className="profileBottom"
            style={isProUser ? {} : { marginTop: 32 }}
          >
            {!isProUser && isOwner ? (
              <div className="doProNotification">
                <h2>{t("profileHero.unlimitedAcc")}</h2>
                <p>{t("profileHero.news")}</p>
                <button
                  className="btn"
                  onClick={() => {
                    if (user?.profile?.profile_type === PROFILE_TYPES.free) {
                      navigate("/dashboard");
                      setDashboardPage("upgrade-pack");
                    }
                  }}
                >
                  {t("profileHero.upgradeBtn")}
                </button>
              </div>
            ) : (
              <>
                {isProUser && (
                  <div
                    className={`profileVideoContainer ${
                      profileData?.social_media?.length === 0 && "toRight"
                    }`}
                  >
                    {(isOwner ||
                      (!isOwner &&
                        profileData?.social_media?.length !== 0)) && (
                      <ProfileSection
                        customComponent={
                          <ProfileSocialMedia profileData={profileData} />
                        }
                        title={t("profileHero.socialMediaLink")}
                        showEditButton={isOwner}
                        onEdit={() =>
                          setModalData({
                            title: t("profileHero.manageSocialMediaLinks"),
                            customComponent: (
                              <ProfileSocialEdit
                                closeModal={closeModal}
                                profileData={profileData}
                                setProfileData={setProfileData}
                              />
                            ),
                            isOpen: true,
                          })
                        }
                      />
                    )}
                    {isProUser && (
                      <div className="videoPlayer">
                        {isOwner && (
                          <div
                            className="editIcon"
                            onClick={() =>
                              setModalData({
                                title: t("profileHero.manageVideo"),
                                customComponent: (
                                  <ProfileVideoEdit
                                    closeModal={closeModal}
                                    profileData={profileData}
                                    setProfileData={setProfileData}
                                  />
                                ),
                                isOpen: true,
                              })
                            }
                          >
                            <img src={PenIcon} alt="pen_icon" />
                          </div>
                        )}
                        {video_url ? (
                          <ReactPlayer
                            url={`https://www.youtube.com/embed/${generateYouTubeEmbedUrlId(
                              video_url
                            )}`}
                          />
                        ) : (
                          isOwner && (
                            <div className="introVideo">
                              <img src={introVideoBg} alt="intro-video-bg" />
                              <div className="playIcon">
                                <img src={playIcon} alt="play-icon" />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}
                {isProUser && (isOwner || profileData?.about) && (
                  <ProfileSection
                    customComponent={<ProfileAbout profileData={profileData} />}
                    title={t("profileHero.about")}
                    showEditButton={isOwner}
                    onEdit={() =>
                      setModalData({
                        title: t("profileHero.manageInformation"),
                        customComponent: (
                          <ProfileAboutEdit
                            closeModal={closeModal}
                            profileData={profileData}
                            setProfileData={setProfileData}
                          />
                        ),
                        isOpen: true,
                      })
                    }
                  />
                )}
                {isProUser &&
                  (isOwner || profileData?.featured_links?.length !== 0) && (
                    <ProfileFeaturedSection
                      isOwner={isOwner}
                      profileData={profileData}
                      setProfileData={setProfileData}
                      onAdd={() =>
                        setModalData({
                          title: t("profileHero.addPortfolio"),
                          customComponent: (
                            <ProfileFeaturedEdit
                              onAdd={() => {
                                setModalData({
                                  title: t("profileHero.managePortfolio"),
                                  customComponent: (
                                    <ProfileFeaturedAdd
                                      closeModal={closeModal}
                                      profileData={profileData}
                                      setProfileData={setProfileData}
                                    />
                                  ),
                                  isOpen: true,
                                });
                              }}
                              closeModal={closeModal}
                              setModalData={setModalData}
                              profileData={profileData}
                              setProfileData={setProfileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                      onEdit={(item) =>
                        setModalData({
                          title: t("profileHero.managePortfolio"),
                          customComponent: (
                            <ProfileFeaturedEdit
                              data={item}
                              onAdd={() => {
                                setModalData({
                                  title: t("profileHero.managePortfolio"),
                                  customComponent: (
                                    <ProfileFeaturedEdit
                                      closeModal={closeModal}
                                      profileData={profileData}
                                      setProfileData={setProfileData}
                                    />
                                  ),
                                  isOpen: true,
                                });
                              }}
                              closeModal={closeModal}
                              setModalData={setModalData}
                              profileData={profileData}
                              setProfileData={setProfileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                      showAddIcon={true}
                      showEditIcon={true}
                    />
                  )}
                {isProUser &&
                  (isOwner || profileData?.experiences?.length !== 0) && (
                    <ProfileSection
                      customComponent={
                        <ProfileExperience profileData={profileData} />
                      }
                      title={t("profileHero.experience")}
                      showEditButton={isOwner}
                      onEdit={() =>
                        setModalData({
                          title: t("profileHero.manageExperience"),
                          customComponent: (
                            <ProfileEditExperience
                              setProfileData={setProfileData}
                              onAdd={(item) => {
                                setModalData({
                                  title: item
                                    ? t("profileHero.editBtn")
                                    : t("profileHero.addExperience"),
                                  customComponent: (
                                    <ProfileCreateExp
                                      closeModal={closeModal}
                                      data={item}
                                      setProfileData={setProfileData}
                                    />
                                  ),
                                  isOpen: true,
                                });
                              }}
                              closeModal={closeModal}
                              profileData={profileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                    />
                  )}
                {isProUser &&
                  (isOwner || profileData?.skills?.length !== 0) && (
                    <ProfileSection
                      customComponent={
                        <ProfileSkills profileData={profileData} />
                      }
                      title={t("profileHero.skills")}
                      showEditButton={isOwner}
                      onEdit={() =>
                        setModalData({
                          title: t("profileHero.manageYourSkills"),
                          customComponent: (
                            <ProfileSkillsEdit
                              closeModal={closeModal}
                              profileData={profileData}
                              setProfileData={setProfileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                    />
                  )}
                {isProUser &&
                  (isOwner || profileData?.languages?.length !== 0) && (
                    <ProfileSection
                      customComponent={
                        <ProfileLanguages profileData={profileData} />
                      }
                      title={t("profileHero.languages")}
                      showEditButton={isOwner}
                      onEdit={() =>
                        setModalData({
                          title: t("profileHero.manageLanguage"),
                          customComponent: (
                            <ProfileLanguageEdit
                              closeModal={closeModal}
                              profileData={profileData}
                              setProfileData={setProfileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                    />
                  )}
                {isProUser &&
                  (isOwner || profileData?.keywords?.length !== 0) && (
                    <ProfileSection
                      customComponent={
                        <ProfileKeywords profileData={profileData} />
                      }
                      title={t("profileHero.keywords")}
                      showEditButton={isOwner}
                      onEdit={() =>
                        setModalData({
                          title: t("profileHero.manageKeywords"),
                          customComponent: (
                            <ProfileKeywordEdit
                              closeModal={closeModal}
                              profileData={profileData}
                              setProfileData={setProfileData}
                            />
                          ),
                          isOpen: true,
                        })
                      }
                    />
                  )}
              </>
            )}
          </div>
        </div>
        {modalData.isOpen && (
          <ProfileEditModal closeModal={closeModal} modalData={modalData} />
        )}
      </section>
      <SharedProfileModal data={user} />
      {isUserIn && <MobileNavBar />}
    </>
  );
};

export default Profile;
