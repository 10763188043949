import React, { useContext, useState } from "react";
import location from "../../../../assets/images/icons/location-blue.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import AddSharedUserModal from "../../../../components/AddSharedUserModal";
import { LoginContext } from "../../../../utils/Auth";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../../../utils/MainContext";
import { useTranslation } from "react-i18next";

const ProfileInfo = ({
  profileData,
  onEditProfileInfo,
  showProfileInfoEdit,
  isOwner,
  isProUser,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isUserIn } = useContext(LoginContext);
  const { setRegisterSuccessMsg } = useContext(MainContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="profileInfo">
      <div className="title">
        <div>
          <h2>{profileData?.full_name}</h2>
        </div>
        {showProfileInfoEdit && isOwner && isProUser && (
          <div className="editIcon" onClick={() => onEditProfileInfo()}>
            <img src={PenIcon} alt="phone_icon" />
          </div>
        )}
      </div>
      <div className="description">
        <span className="boxCount">
          {profileData.in_boxes_count} <span>{t("profileCard.saved")}</span>
        </span>
        <div className="bio">
          <div>
            {isProUser && <p>{profileData?.profession?.name}</p>}
            {isProUser && <p>{profileData?.bio}</p>}
          </div>
        </div>
        {isProUser && (
          <div className="location">
            {profileData?.address && profileData?.address !== "" && (
              <img src={location} alt="location" />
            )}
            <Link
              to={`https://www.google.com/maps?q=${profileData?.address}`}
              target="_blank"
            >
              {profileData?.address}
            </Link>
          </div>
        )}
        {!showProfileInfoEdit && !isOwner && (
          <button
            className={`addBtn ${
              profileData?.in_boxes_by_owner?.length !== 0 && "added"
            }`}
            onClick={() => {
              if (profileData?.in_boxes_by_owner?.length === 0) {
                if (isUserIn) {
                  setModalIsOpen(true);
                } else {
                  sessionStorage.setItem(
                    "sharedToBox",
                    JSON.stringify(profileData.username)
                  );
                  setRegisterSuccessMsg(t("profileInfo.registerNotify"));
                  navigate("/register");
                }
              } else {
                return;
              }
            }}
          >
            +{" "}
            {profileData?.in_boxes_by_owner?.length === 0
              ? t("profileCard.add")
              : t("profileCard.added")}
          </button>
        )}
      </div>
      <AddSharedUserModal
        data={profileData}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default ProfileInfo;
