import { useContext } from "react";

// Icons
import azFlag from "../assets/images/icons/az_flag.svg";
import enFlag from "../assets/images/icons/en_flag.svg";
import ruFlag from "../assets/images/icons/ru_flag.svg";

// i18next translation
import { useTranslation } from "react-i18next";
import { MainContext } from "../utils/MainContext";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  // Globa; State
  const { langModalVivible, setLangModalVisible, changeLanguage } =
    useContext(MainContext);

  return (
    <div
      className="selectLang"
      onClick={() => setLangModalVisible(!langModalVivible)}
    >
      <div>
        {i18n.language === "az" ||
        i18n.language === "tr" ||
        i18n.language === "tr-TR"
          ? "AZ"
          : i18n.language === "en"
          ? "EN"
          : i18n.language === "ru"
          ? "РУ"
          : ""}
      </div>
      <div className={langModalVivible ? "overlay active" : "overlay"}></div>
      <ul className={langModalVivible ? "langBox active" : "langBox"}>
        <li className="seperator"></li>
        <li className="lang" id="az-AZ" onClick={() => changeLanguage("az")}>
          <img src={azFlag} alt="az" />
          Azərbaycanca
        </li>
        <li className="lang" id="en-US" onClick={() => changeLanguage("en")}>
          <img src={enFlag} alt="en" />
          English
        </li>
        <li className="lang" id="ru-RU" onClick={() => changeLanguage("ru")}>
          <img src={ruFlag} alt="ru" />
          Русский
        </li>
      </ul>
    </div>
  );
};

export default LanguageSelector;
