export const paymentsHistoryList = [
  {
    id: 1,
    status: "SUCCESS",
    order: 123456789,
    paymentDate: "12.02.24",
    amount: 234,
    cardNumber: "xxxx xxxx xxxx 1622",
  },
  {
    id: 2,
    status: "FAILED",
    order: 147258369,
    paymentDate: "12.01.25",
    amount: 56,
    cardNumber: "xxxx xxxx xxxx 0000",
  },
];
