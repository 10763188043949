import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Verification = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [type, status] = location.search
    .replace("?type=", "")
    .split("?status=");

  return (
    <section className="verification">
      <div className="container">
        <div className="row">
          <div className="verificationContainer">
            <h2 className="infoText">
              {type === "email"
                ? t("verification.email")
                : t("verification.phone")}{" "}
              {t("verification.verify")}{" "}
              {status === "failed"
                ? t("verification.fail")
                : t("verification.success")}{" "}
              {t("verification.be")}.
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Verification;
