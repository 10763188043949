import React, {useState} from "react";
import * as ProfileService from "../../Service/ProfileService";
import OtpForm from "../../../../components/OtpForm";
import Spinner from "../../../../components/Spinner";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import {useTranslation} from "react-i18next";
import {toastMsg} from "../../../../utils/swal";
import useCountryCodeDetector from "../../../../hooks/useCountryCodeDetector";

const ProfilePhoneNumbersEdit = ({
  profileData,
  closeModal,
  setProfileData,
}) => {
  const { t } = useTranslation();
  const countryCode = useCountryCodeDetector();
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_phone_number: profileData?.extra_phone_numbers || [],
    phone_number: profileData?.phone_number || [],
  });

  const [newPhoneNumber, setNewPhoneNumber] = useState({
    phone_number: "",
    isVerified: false,
  });

  const [isEditPrimaryPhone, setIsEditPrimaryPhone] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await ProfileService.profileEdit(profileInfo);
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const phoneNumber = value?.trim();
    setNewPhoneNumber({ phone_number: phoneNumber });
  };

  const verifyPhone = async (e, phone) => {
    setLoading(true);
    e.preventDefault();
    const body = {
      extra_phone_number: phone ? phone : newPhoneNumber.phone_number,
    };
    try {
      if (profileInfo?.phone_number !== newPhoneNumber?.phone_number) {
        if (body.extra_phone_number !== 0 || phone) {
          if (!phone) {
            await ProfileService.profileEdit({
              extra_phone_number: [
                phone ? { phone_number: phone } : newPhoneNumber,
              ],
            });
          } else {
            setNewPhoneNumber({ phone_number: phone });
          }
          window.location.reload();
        }
      } else {
        toastMsg(t("profilePhoneEdit.alreadyAdded"), "#ffa700", "#000000");
        setNewPhoneNumber({
          phone_number: "",
          isVerified: false,
        });
      }
    } catch (error) {
      if (error?.response?.data?.extra_phone_number?.includes("exists")) {
        toastMsg(t("profilePhoneEdit.alreadyAdded"), "#ffa700", "#000000");
        setNewPhoneNumber({
          phone_number: "",
          isVerified: false,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const removePhone = async (phone) => {
    Swal.fire({
      title: phone?.phone_number,
      text: t("profilePhoneEdit.confirmDelete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("profilePhoneEdit.yes"),
      cancelButtonText: t("profilePhoneEdit.no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "extra_phone_number",
            id: phone.id,
          });
          setProfileData((prev) => ({
            ...prev,
            extra_phone_numbers: res.data.extra_phone_numbers,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            extra_phone_number: res.data.extra_phone_numbers,
          }));
          Swal.fire(
            t("profilePhoneEdit.success"),
            t("profilePhoneEdit.phoneDeleted"),
            "success"
          );
          closeModal();
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire(
          t("profilePhoneEdit.canceled"),
          t("profilePhoneEdit.cancelDesc"),
          "error"
        );
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return showOtpModal ? (
    <OtpForm
      onSubmit={(verified) => {
        if (verified) {
          closeModal();
          window.location.reload();
        }
      }}
      phone={`${newPhoneNumber.phone_number}`}
    />
  ) : (
    <div className="profilePhoneEdit">
      <div className="editPhoneArea">
        {profileInfo.extra_phone_number.map((phone) => (
          <button
            key={phone.id}
            className="phoneCaption"
            onClick={() => removePhone(phone)}
          >
            {phone.phone_number}
            <span>x</span>
          </button>
        ))}
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="infoPhoneForm">
        <div className="phoneBox">
          <label htmlFor="phone">{t("login.phone")} *</label>
          <PhoneInput
            international
            defaultCountry={countryCode || "AZ"}
            value={newPhoneNumber?.phone_number}
            onChange={handleChange}
            limitMaxLength={19}
            disabled={isEditPrimaryPhone}
            style={{ opacity: isEditPrimaryPhone ? 0.5 : 1 }}
          />
        </div>
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("profilePhoneEdit.cancel")}
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={newPhoneNumber.isVerified ? onSubmit : verifyPhone}
          disabled={errorMessage !== ""}
        >
          {t("profilePhoneEdit.save")}
        </button>
      </div>
    </div>
  );
};

export default ProfilePhoneNumbersEdit;
