import React from "react";
import useSettingsTabList from "../../../../constants/useSettingsTabList";
import rightArrow from "../../../../assets/images/icons/arrow-right.svg";

const SettingsSideBar = ({ onClickTab, activeTab }) => {
  const settingsTabs = useSettingsTabList();
  return (
    <aside className="settingsSideBar">
      <ul className="settingList">
        {settingsTabs
          .filter((tab) => tab.visible)
          .map((item) => (
            <li
              className={`settingsListItem ${
                activeTab === item.id && "active"
              }`}
              key={item.id}
              onClick={() => onClickTab(item.id)}
            >
              {item.title}
              <img src={rightArrow} alt="right-arrow" />
            </li>
          ))}
      </ul>
    </aside>
  );
};

export default SettingsSideBar;
