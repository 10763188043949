import React from "react";

const SettingsModal = ({ modalData, closeModal }) => {
  return (
    <div className="settingsModal">
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="content">
        <p className="modalTitle">{modalData.title}</p>
        {modalData.customComponent}
      </div>
    </div>
  );
};

export default SettingsModal;
