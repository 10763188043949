// Images
import { useContext } from "react";
import { $api } from "../api/api";
import addUser from "../assets/images/icons/add-user.svg";
import bIcon from "../assets/images/icons/b-icon.svg";

// Translation
import { useTranslation } from "react-i18next";
import { LoginContext } from "../utils/Auth";
import { PROFILE_TYPES } from "../constants/constants";

const SharedUserCard = ({ data, onClick, onRemove }) => {
  // Translation
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);

  const isPro = data?.profile_type === PROFILE_TYPES.personal;

  return (
    <div className="sharedUserCard">
      <div
        style={{
          backgroundImage: data?.background_image
            ? `url(${
                data?.background_image.includes("api.")
                  ? ""
                  : $api("image_url") + "/"
              }${data?.background_image})`
            : "",
        }}
        className="background"
      >
        <div
          className={`avatar ${isPro && "avatarPro"} ${
            data?.avatar === null && "isDefault"
          }`}
        >
          {data?.avatar ? (
            <img
              src={data?.avatar ? data?.avatar : data?.avatar}
              alt={data.full_name}
            />
          ) : (
            <img src={bIcon} alt="default-icon" className="defaultIcon" />
          )}
          {isPro && (
            <span
              className={`badge ${
                data?.avatar === null && "defaultBadgePosition"
              }`}
            >
              {t("sharedUserCard.pro")}
            </span>
          )}
        </div>
      </div>
      <div className="cardInfo">
        <div className="userInfo">
          <a href={`/profile/${data?.username}`} className="userName">
            {data?.full_name}
          </a>
          <p className="boxCount">
            {data?.in_boxes_count} {t("sharedUserCard.boxes")}
          </p>
          <a href={`tel:${data?.phone_number}`} className="phoneNumber">
            {data?.phone_number}
          </a>
        </div>
        {data?.in_boxes_by_owner?.length === 0 ? (
          <div className="addUserBtn" onClick={onClick}>
            {user?.profile?.id !== data.id && (
              <img src={addUser} alt="add-icon" />
            )}
            <span>
              {user?.profile?.id === data.id
                ? t("sharedUserCard.goProfile")
                : t("sharedUserCard.add")}
            </span>
          </div>
        ) : (
          <div className="removeFromBox" onClick={onRemove}>
            <span>{t("sharedUserCard.deleteFromBox")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedUserCard;
