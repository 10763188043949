import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import backArrow from "../../../assets/images/icons/back-arrow.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import MobileLanguageSelect from "../../../components/MobileLanguageSelect";
import {MainContext} from "../../../utils/MainContext";
import ForgotForm from "./ForgotForm";
import ForgotOtpStep from "./ForgotOtpStep";

const ForgotPassword = () => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setPhone, forgotStep, mobileLangVisivle } = useContext(MainContext);
  const [isEmail, setIsEmail] = useState("");

  // Router
  const navigate = useNavigate();

  // Reset input
  useEffect(() => {
    setPhone("");
  }, [setPhone, navigate]);

  return (
    <main>
      <section className="forgotPassword">
        <div className="screen">
          <div className="leftSide">
            <div className="formHeader">
              <div
                className="navigate"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={backArrow} alt="go-back" />
                <p className="navigateInfo">{t("forgotPassword.goBack")}</p>
              </div>
              <LanguageSelector />
            </div>
            <div className="formSteps">
              {forgotStep === 1 ? (
                <ForgotForm setIsEmail={setIsEmail} />
              ) : (
                <ForgotOtpStep isEmail={isEmail} />
              )}
            </div>
          </div>
          <div className="rightSide"></div>
        </div>
        {mobileLangVisivle && <MobileLanguageSelect />}
      </section>
    </main>
  );
};

export default ForgotPassword;
