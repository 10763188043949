import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {object, string} from "yup";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const StepTwo = ({ isActivated }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { phone, setStep } = useContext(MainContext);

  // Local states
  const [seconds, setSeconds] = useState(120);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [resendLimit, setResendLimit] = useState(3);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For countdown timer
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          setIsCountdownOver(true);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Callback for reSendOtp to avoid dependency issues
  const reSendOtp = useCallback(async () => {
    setLoading(true);
    const body = {
      phone_number: phone,
    };

    try {
      await AuthService.resendOtp(body);
      setResendLimit((prevLimit) => prevLimit - 1);
      setSeconds(120);
      setIsCountdownOver(false);
    } catch (err) {
      setErrorMsg(err?.response?.data || t("registerStepThree.errorResend"));
    } finally {
      setLoading(false);
    }
  }, [phone, t]);

  useEffect(() => {
    if (isActivated) {
      reSendOtp();
    }
  }, [isActivated, reSendOtp]);

  // Yup otp schema
  const otpSchema = object({
    otp: string()
      .required(t("registerStepThree.errorMsg1"))
      .min(4, t("registerStepThree.errorMsg2"))
      .matches(/^\d{4}$/, t("registerStepThree.errorMsg3")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpSchema),
  });

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setLoading(true);

    const body = {
      phone_number: phone,
      otp: data.otp,
    };

    try {
      await AuthService.confirmOtp(body);
      setStep(3);
    } catch (err) {
      setErrorMsg(t("registerStepThree.confirmError"));
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  // Reload page when limit reached
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">
        {errorMsg ? "Diqqət !!!" : t("registerStepThree.formTitle")}
      </h2>
      {isCountdownOver
        ? !errorMsg && (
            <p className="question">
              {resendLimit === 0
                ? t("registerStepThree.limit")
                : t("registerStepThree.time")}
              <span
                onClick={resendLimit === 0 ? reloadPage : reSendOtp}
                className="reSend"
              >
                {resendLimit === 0
                  ? t("registerStepThree.again")
                  : t("registerStepThree.send")}
              </span>
            </p>
          )
        : !errorMsg && (
            <p className="question">
              {t("registerStepThree.info")} <span>{phone}</span> <br />{" "}
              {t("registerStepThree.resend")}
              <span className="timer">
                {minutes}:{remainingSeconds < 10 ? "0" : ""}
                {remainingSeconds}
              </span>
              {t("registerStepThree.left")}
            </p>
          )}
      <div className="inputBox">
        {!errorMsg && (
          <input
            type="text"
            className={errors.otp || errorMsg ? "formInput error" : "formInput"}
            name="otp"
            maxLength="4"
            {...register("otp")}
          />
        )}
        {errors.otp && <span className="errorAlert">{errors.otp.message}</span>}
        {errorMsg && <span className="errorAlert">{errorMsg}</span>}
        {errorMsg && (
          <p className="regError">
            <Link to="/">{t("forgotPassword.goBack")}</Link>
          </p>
        )}
      </div>
      {!errorMsg && (
        <Button
          className="formBtn"
          type="submit"
          disabled={!!errors.otp || isCountdownOver || loading}
        >
          {t("registerStepThree.btnValue")}
        </Button>
      )}
    </form>
  );
};

export default StepTwo;
