import React from "react";
import {useTranslation} from "react-i18next";

const PrivateStatusModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className="privateStatusModal">
      <ul className="privateDescList">
        <li className="descListItem">{t("settings.switchDescListItemOne")}</li>
        <li className="descListItem">{t("settings.switchDescListItemTwo")} </li>
        <li className="descListItem">
          {t("settings.switchDescListItemThree")}
        </li>
      </ul>
      <div className="btnGroup">
        <button className="btn cancel" onClick={onCancel}>
          {t("settings.cancel")}
        </button>
        <button className="btn" onClick={onSubmit}>
          {t("settings.switch")}
        </button>
      </div>
    </div>
  );
};

export default PrivateStatusModal;
