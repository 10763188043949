import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import PacketCard from "../../../components/PacketCard";
import * as HomeService from "../Service/HomeService";
import {MainContext} from "../../../utils/MainContext";
import Loading from "../../../components/Loading";

const Packets = () => {
  const { t } = useTranslation();
  const { user } = useContext(MainContext);
  const [anually, setAnually] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      try {
        const res = await HomeService.planAllList();
        setPlanList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="packets" id="bucardPackets">
      <div className="container">
        <div className="row">
          <div className="packetsHead">
            <h2 className="packetsTitle">{t("packets.title")}</h2>
            <div className="btnGroup">
              <button
                className={anually ? "tabBtn" : "tabBtn active"}
                onClick={() => setAnually(false)}
              >
                {t("packets.month")}
              </button>
              <button
                className={anually ? "tabBtn active" : "tabBtn"}
                onClick={() => setAnually(true)}
              >
                {t("packets.year")}
              </button>
            </div>
          </div>
          <div className="selectPacket">
            {planList
              .filter((plan) => plan.profile_type !== "business")
              .map((item) => (
                <PacketCard
                  id={item.id}
                  data={item}
                  key={item.id}
                  state={anually}
                  profile_type={user?.profile?.profile_type}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Packets;
