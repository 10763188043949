import React, {useContext, useState} from "react";
import CustomSwitch from "../../../../components/CustomSwitch";
import {useTranslation} from "react-i18next";
import * as ProfileService from "../../../Profile/Service/ProfileService";
import {LoginContext} from "../../../../utils/Auth";
import Loading from "../../../../components/Loading";
import {toastMsg} from "../../../../utils/swal";
import PrivateStatusModal from "./modals/PrivateStatusModal";

const ProfileStatusTab = ({ setModalData, closeModal }) => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const [privateStatus, setPrivateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeStatus = async (value) => {
    setModalData({
      title: t("settings.profileStatusSwitch"),
      isOpen: true,
      customComponent: (
        <PrivateStatusModal
          onCancel={() => {
            setPrivateStatus(privateStatus);
            closeModal();
          }}
          onSubmit={async () => {
            setLoading(true);
            try {
              await ProfileService.profileEdit({
                is_private: value,
              });
              toastMsg(t("settings.success.isPrivate"), "#27e574", "#000000");
              closeModal();
            } catch (error) {
              toastMsg("errors.systemError", "#ff0000", "#000000");
              console.log(error);
            } finally {
              setLoading(false);
            }
          }}
        />
      ),
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="profileStatusTab">
      <h3>{t("settings.accountPrivacy")}</h3>
      <div className="tabHead">
        <h4>{t("settings.privateAccount")}</h4>
        <CustomSwitch
          onChange={(value) => {
            setPrivateStatus(value);
            onChangeStatus(value);
          }}
          checked={privateStatus}
        />
      </div>
      <p className="desc">{t("settings.privateAccountDesc")}</p>
    </div>
  );
};

export default ProfileStatusTab;
