import {toastMsg} from "./swal";

export const generateYouTubeEmbedUrlId = (url) => {
  let regex = /[?&]v=([^#\&\?]*).*/;
  let match = url.match(regex);
  if (!match) {
    regex = /youtu\.be\/([^#\&\?]*).*/;
    match = url.match(regex);
  }
  if (match && match[1]) {
    const videoId = match[1];
    return videoId;
  } else {
    return null;
  }
};

export const getEmploymentType = (type, t) => {
  let employment_type = "";
  switch (type) {
    case "full_time":
      employment_type = t("employmentTypes.full_time");
      break;
    case "part_time":
      employment_type = t("employmentTypes.part_time");
      break;
    case "internship":
      employment_type = t("employmentTypes.internship");
      break;
    case "contract":
      employment_type = t("employmentTypes.contract");
      break;
    case "freelance":
      employment_type = t("employmentTypes.freelance");
      break;
    case "self_employed":
      employment_type = t("employmentTypes.self_employed");
      break;
    case "volunteer":
      employment_type = t("employmentTypes.volunteer");
      break;
    default:
      employment_type = "";
      break;
  }
  return employment_type;
};

export const handleCopyToClipboard = async (
  textForCopy,
  successText,
  errorText
) => {
  try {
    if (textForCopy) {
      await navigator.clipboard.writeText(textForCopy);
      toastMsg(successText || "Successfully copied !", "#12db63", "#ffffff");
    }
  } catch (err) {
    console.error("Error on copying", err);
    toastMsg(errorText || "Error on copy !", "#ed4611", "#000000");
  }
};

export const getSiteName = (url) => {
  try {
    const normalizedUrl = url.startsWith("http") ? url : `http://${url}`;
    const domain = new URL(normalizedUrl).hostname;
    const siteName = domain.replace("www.", "").split(".")[0];
    return siteName.charAt(0).toUpperCase() + siteName.slice(1).toLowerCase();
  } catch (error) {
    console.log("Invalid URL");
  }
};

export const isValidUrl = (url) => {
  const urlRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
  return urlRegex.test(url);
};
