import React, {useState} from "react";
import rightArrow from "../../../../assets/images/icons/arrow-right.svg";
import {accountTabEnum} from "../../../../constants/constants";
import GeneralTab from "./GeneralTab";
import ContactInformationsTab from "./ContactInformationsTab";
import {useTranslation} from "react-i18next";
import {useAccountTabList} from "../../../../constants/useAccountTabList";

const AccountTab = ({ setModalData, closeModal }) => {
  const { t } = useTranslation();
  const [activeAccountTab, setActiveAccountTab] = useState(null);
  const accountTabs = useAccountTabList();

  const renderAccountTab = () => {
    switch (activeAccountTab) {
      case accountTabEnum.general:
        return <GeneralTab />;
      case accountTabEnum.contactInformations:
        return (
          <ContactInformationsTab
            setModalData={setModalData}
            closeModal={closeModal}
          />
        );
      default:
        return <GeneralTab />;
    }
  };

  return (
    <div
      className="accountTab"
      style={
        activeAccountTab === accountTabEnum.contactInformations
          ? {
              border: "none",
              borderRadius: 0,
              backgroundColor: "transparent",
              padding: 0,
            }
          : {}
      }
    >
      {!activeAccountTab && (
        <div className="accountTabHeader">
          <h2>{t("settings.account")}</h2>
          <p>{t("settings.tabDesc")}</p>
        </div>
      )}
      {!activeAccountTab ? (
        <ul className="accountTabList">
          {accountTabs
            .filter((tab) => tab.visible)
            .map((item) => (
              <li
                className={`accountTabListItem ${
                  item.id === activeAccountTab && "active"
                }`}
                key={item.id}
                onClick={() => setActiveAccountTab(item.id)}
              >
                {item.title}
                <div className="icon">
                  <img src={rightArrow} alt="rightArrow" />
                </div>
              </li>
            ))}
        </ul>
      ) : (
        renderAccountTab()
      )}
    </div>
  );
};

export default AccountTab;
