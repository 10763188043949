import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import {confirmSwal} from "../../../../utils/swal.js";
import {useTranslation} from "react-i18next";

const ProfileVideoEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = useState(
    profileData.intro_videos[0]?.intro_video_url
  );
  const [isEdit, setIsEdit] = useState(false);
  const buttonIsDisabled = videoUrl?.trim().length === 0;

  const showEnviroments =
    profileData.intro_videos[0]?.intro_video_url &&
    profileData.intro_videos[0]?.intro_video_url?.length !== "" &&
    !isEdit;

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await ProfileService.profileEdit({
        intro_videos: [
          {
            id: profileData.intro_videos[0]?.id,
            intro_video_url: videoUrl,
          },
        ],
      });
      setProfileData((prev) => ({
        ...prev,
        intro_videos: res.intro_videos,
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const removeVideoLink = (e) => {
    e.preventDefault();
    confirmSwal(
      "",
      t("profileVideoEdit.confirmDelete"),
      t("profileVideoEdit.yes"),
      t("profileVideoEdit.no"),
      () => {},
      async () => {
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "intro_video",
            id: profileData.intro_videos[0]?.id,
          });
          setProfileData((prev) => ({
            ...prev,
            intro_videos: res.data.intro_videos,
          }));
          closeModal();
        } catch (err) {
          console.log(err);
        }
      },
      t("profileVideoEdit.completed"),
      t("profileVideoEdit.cancel")
    );
  };

  return (
    <div className="profileInfoEdit">
      <form className="infoEditForm videoEdit">
        {showEnviroments ? (
          <div className="btnGroup fullSize">
            <button className="btn delete" onClick={removeVideoLink}>
              {t("profileVideoEdit.delete")}
            </button>
            <button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setIsEdit(true);
              }}
            >
              {t("profileVideoEdit.change")}
            </button>
          </div>
        ) : (
          <CustomInput
            label={t("profileVideoEdit.videoLink")}
            placeholder={t("profileVideoEdit.videoLinkPlace")}
            value={videoUrl}
            onChange={(value) => {
              setVideoUrl(value);
            }}
          />
        )}
        {!showEnviroments && (
          <div className="infoSkills">
            <img src={infoIcon} alt="" />
            {t("profileVideoEdit.validUrl")}
          </div>
        )}
        {!showEnviroments && (
          <div className="btnGroup">
            <button
              className="btn cancel"
              onClick={(e) => {
                e.preventDefault();
                if (isEdit) {
                  setIsEdit(false);
                } else {
                  closeModal();
                }
              }}
            >
              {t("profileVideoEdit.cancel")}
            </button>
            <button
              disabled={buttonIsDisabled}
              className={`btn ${buttonIsDisabled && "disabled"}`}
              onClick={onSubmit}
            >
              {t("profileVideoEdit.save")}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileVideoEdit;
