import { useContext } from "react";

// Router
import { Link, useLocation } from "react-router-dom";

// Images
import logo from "../assets/images/logo.svg";

// Translation
import { useTranslation } from "react-i18next";

// Components
import LanguageSelector from "./LanguageSelector";
import MobileLanguageSelect from "./MobileLanguageSelect";

// Context
import { LoginContext } from "../utils/Auth";
import { MainContext } from "../utils/MainContext";

const Header = () => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { isUser } = useContext(LoginContext);
  const { mobileLangVisivle } = useContext(MainContext);
  const path = useLocation();

  // Scroll to clicked section
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -70;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="logo" onClick={() => scrollToSection("hero")}>
            <Link to="/">
              <img src={logo} alt="bucard-logo" />
            </Link>
          </div>
          <nav className="navBar">
            <ul className="navList">
              {!path.pathname.includes("/payment-info") && (
                <>
                  <li
                    className="navItem whatIs"
                    onClick={() => scrollToSection("whatIsBucard")}
                  >
                    {t("header.whatIs")}
                  </li>
                  <li
                    className="navItem"
                    onClick={() => scrollToSection("bucardPackets")}
                  >
                    {t("header.packets")}
                  </li>
                  <li className="navItem">
                    <a href="https://blog.bucard.az" target="_blank">
                      {t("header.blog")}
                    </a>
                  </li>
                  <li
                    className="navItem"
                    onClick={() => scrollToSection("contacts")}
                  >
                    {t("header.contact")}
                  </li>
                </>
              )}
              <LanguageSelector />
              {!isUser && (
                <Link className="signIn" to="/login">
                  <p>{t("header.login")}</p>
                </Link>
              )}
            </ul>
          </nav>
        </div>
      </div>
      {mobileLangVisivle && <MobileLanguageSelect />}
    </header>
  );
};

export default Header;
