import React, {useEffect, useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import {useDebounce} from "use-debounce";
import {useTranslation} from "react-i18next";

const ProfileFeaturedEdit = ({
  profileData,
  setProfileData,
  data,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState(profileData?.url || "");
  const [delayedUrl] = useDebounce(url, 500);
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title || "",
    description: profileData?.description || "",
    url: url,
    image_url: profileData.image_url || "",
  });

  useEffect(() => {
    if (data) {
      setProfileInfo(data);
      setUrl(data.url);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({
        featured_link: profileInfo,
      });
      setProfileData(res);
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const urlMetaData = async (url) => {
    try {
      const res = await ProfileService.urlMetaDatas({ url });
      setProfileInfo((prev) => ({
        ...prev,
        image_url: res.thumbnail !== null ? res.thumbnail : "https://bucard.az",
        url,
      }));
    } catch (err) {
      console.log("URL Error", err);
    }
  };

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.([a-z]{2,})|localhost)|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    const isYouTubeUrl =
      /^https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(&[\w=&-]*)*$/i;
    const isYouTubeShortUrl = /^https:\/\/youtu\.be\/[\w-]+$/i;

    return (
      urlPattern.test(url) ||
      isYouTubeUrl.test(url) ||
      isYouTubeShortUrl.test(url)
    );
  };

  useEffect(() => {
    if (delayedUrl && isValidUrl(delayedUrl)) {
      urlMetaData(delayedUrl);
    }
  }, [delayedUrl]);

  return (
    <div className="profileFeaturedEdit">
      <form className="infoEditForm" onSubmit={onSubmit}>
        <CustomInput
          label={t("profileFeaturedEdit.title")}
          placeholder={t("profileFeaturedEdit.titlePlace")}
          onChange={(value) =>
            setProfileInfo((prev) => ({ ...prev, title: value }))
          }
          value={profileInfo.title}
        />
        <CustomInput
          label={t("profileFeaturedEdit.desc")}
          placeholder={t("profileFeaturedEdit.descPlace")}
          onChange={(value) =>
            setProfileInfo((prev) => ({ ...prev, description: value }))
          }
          value={profileInfo.description}
        />
        <CustomInput
          label={t("profileFeaturedEdit.link")}
          placeholder={t("profileFeaturedEdit.linkPlace")}
          onChange={(value) => setUrl(value)}
          value={url}
        />
        <div className="btnGroup">
          <button
            className="btn cancel"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            {t("profileFeaturedEdit.cancel")}
          </button>
          <button className="btn" type="submit" onClick={onSubmit}>
            {loading
              ? t("profileFeaturedEdit.wait")
              : t("profileFeaturedEdit.save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileFeaturedEdit;
