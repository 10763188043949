import {useTranslation} from "react-i18next";
import {settingsTabsEnum} from "./constants";

const useSettingsTabList = () => {
  const { t } = useTranslation();

  return [
    {
      id: settingsTabsEnum.account,
      title: t("settings.account"),
      visible: true,
    },
    {
      id: settingsTabsEnum.security,
      title: t("settings.security"),
      visible: true,
    },
    {
      id: settingsTabsEnum.plan,
      title: t("settings.plan"),
      visible: true,
    },
    {
      id: settingsTabsEnum.payments,
      title: t("settings.payments"),
      visible: false,
    },
    {
      id: settingsTabsEnum.language,
      title: t("settings.language"),
      visible: false,
    },
    {
      id: settingsTabsEnum.privacy,
      title: t("settings.privacy"),
      visible: false,
    },
    {
      id: settingsTabsEnum.deactivate,
      title: t("settings.deactivate"),
      visible: false,
    },
    {
      id: settingsTabsEnum.data,
      title: t("settings.data"),
      visible: false,
    },
    {
      id: settingsTabsEnum.about,
      title: t("settings.about"),
      visible: false,
    },
  ];
};

export default useSettingsTabList;
