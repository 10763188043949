import React, {useEffect, useRef, useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import Spinner from "../../../../components/Spinner";
import {toastMsg} from "../../../../utils/swal";
import {useTranslation} from "react-i18next";

const ProfileKeywordEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(profileData?.keywords || []);
  const [keywords, setKeywords] = useState({
    keyword: "",
  });
  const oldKeywords = useRef();

  useEffect(() => {
    oldKeywords.current = profileInfo;
  }, []);

  const removeKeyword = async (keyword) => {
    setLoading(true);
    try {
      if (keyword.id) {
        const res = await ProfileService.deleteProfileDetail({
          field_name: "keyword",
          id: keyword.id,
        });
        setProfileData((prev) => ({
          ...prev,
          keywords: res.data.keywords,
        }));
        setProfileInfo(res.data.keywords);
      } else {
        setProfileInfo((prev) => prev.filter((item) => item !== keyword));
      }
    } catch (err) {
      console.error("Error removing keyword:", err);
    } finally {
      setLoading(false);
    }
  };

  const addKeywords = () => {
    if (profileInfo.length === 20) {
      toastMsg(t("profileKeywordEdit.keywordLimitNotify"));
    } else {
      const existing = profileInfo?.find(
        (item) =>
          item?.keyword?.toLowerCase() === keywords?.keyword?.toLowerCase()
      );
      if (!existing) {
        setProfileInfo((prev) => [...prev, keywords]);
      } else {
        toastMsg(t("profileKeywordEdit.alreadyAdded"));
      }
    }
    setKeywords({
      keyword: "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({ keywords: profileInfo });
      setProfileData(res);
      setProfileInfo(res);
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async () => {
    setLoading(true);
    const missingKeywords = oldKeywords.current.filter((keyw) => {
      return !profileInfo.some(
        (item) => item.keyword.toLowerCase() === keyw.keyword.toLowerCase()
      );
    });
    try {
      if (missingKeywords.length !== 0) {
        const res = await ProfileService.profileEdit({
          keywords: [...profileInfo, ...missingKeywords],
        });
        setProfileData(res);
        setProfileInfo(res);
      }
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileKeywordsEdit">
      <div className="editSkillsArea">
        {profileInfo.map((keyword, index) => (
          <button key={index} className="skillsCaption">
            {keyword.keyword}
            <span onClick={() => removeKeyword(keyword)}>x</span>
          </button>
        ))}
      </div>
      <div className="addSkillsArea">
        <div className="row">
          <CustomInput
            className="inp"
            onChange={(value) => {
              setKeywords({
                keyword: value,
              });
            }}
            label={t("profileKeywordEdit.keyword")}
            placeholder={t("profileKeywordEdit.keywordPlace")}
            value={keywords.keyword}
          />
          <div className="addBtn">
            <button
              onClick={addKeywords}
              disabled={
                keywords.keyword.trim() === "" ||
                keywords.keyword.trim()?.length < 2
              }
            >
              +
            </button>
          </div>
        </div>
        <div className="infoSkills">
          <img src={infoIcon} alt="Info Icon" />
          {t("profileKeywordEdit.keywordLimit")}
        </div>
      </div>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {t("profileKeywordEdit.cancel")}
        </button>
        <button
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            if (profileInfo.length < 21) {
              onSubmit(e);
            } else {
              closeModal();
            }
          }}
        >
          {t("profileKeywordEdit.save")}
        </button>
      </div>
    </div>
  );
};

export default ProfileKeywordEdit;
