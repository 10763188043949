import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import * as ProfileService from "../../Service/ProfileService";
import Spinner from "../../../../components/Spinner";
import Close from "../../../../assets/images/icons/close-square.svg";
import {SOCIAL_MEDIA_TYPES} from "../../../../constants/constants";
import {v4 as uuidv4} from "uuid";
import validator from "validator";
import {useTranslation} from "react-i18next";

const ProfileSocialEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    social_media:
      profileData?.social_media?.length !== 0
        ? profileData?.social_media
        : [
            {
              id: uuidv4(),
              profile: "",
              url: "",
            },
          ],
  });
  const [errorMessages, setErrorMessages] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({
        social_media: profileInfo.social_media.filter(
          (item) => item.url !== ""
        ),
      });
      setProfileData((prev) => ({
        ...prev,
        social_media: res.social_media,
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addNewUrl = () => {
    setProfileInfo((prev) => ({
      ...prev,
      social_media: [
        ...prev.social_media,
        {
          id: uuidv4(),
          profile: "",
          url: "",
        },
      ],
    }));
  };

  const removeUrl = async (socialItem) => {
    setLoading(true);
    try {
      if (!socialItem.updated_at) {
        setProfileInfo((prev) => ({
          ...prev,
          social_media: prev.social_media.map((item) =>
            item.id === socialItem.id ? { ...item, url: "" } : item
          ),
        }));
      } else {
        if (socialItem.id && socialItem.url) {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "social_media",
            id: socialItem.id,
          });
          setProfileData((prev) => ({
            ...prev,
            social_media: res?.data?.social_media,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            social_media: res?.data?.social_media,
          }));
        } else {
          setProfileInfo((prev) => ({
            ...prev,
            social_media: prev.social_media.filter(
              (item) => item !== socialItem
            ),
          }));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (value, input) => {
    let error = "";
    if (value && !validator.isURL(value)) {
      error = t("profileSocialEdit.invalidUrl");
    }

    const findedType = Object.values(SOCIAL_MEDIA_TYPES).find((item) =>
      String(value).toLowerCase().includes(item.toLowerCase())
    );
    const updated = profileInfo.social_media.map((item) => {
      if (input.id === item.id) {
        return {
          ...item,
          url: value,
          type: item.type || findedType,
        };
      } else {
        return item;
      }
    });
    setProfileInfo((prev) => ({
      ...prev,
      social_media: updated,
    }));

    setErrorMessages((prev) => ({
      ...prev,
      [input.id]: error,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileSocialEdit">
      <form className="infoSocialForm">
        {profileInfo.social_media.map((item) => (
          <div className="socialInp" key={item.id}>
            <CustomInput
              placeholder={t("profileSocialEdit.enterLink")}
              onChange={(value) => handleChangeInput(value, item)}
              value={item.url}
              errorMessage={errorMessages[item.id] && errorMessages[item.id]}
            />
            <button
              type="button"
              onClick={() => removeUrl(item)}
              className="deleteBtn"
              style={{
                marginTop: errorMessages[item.id] ? -30 : 0,
              }}
            >
              <img src={Close} alt="close_icon" />
            </button>
          </div>
        ))}
      </form>
      <div className="btnGroup">
        <div className="addBtn">
          <button className="btnAdd" type="button" onClick={addNewUrl}>
            <img src={addCircle} alt="add_icon" />
            {t("profileSocialEdit.add")}
          </button>
        </div>
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("profileSocialEdit.cancel")}
        </button>
        <button
          className="btn"
          disabled={Object.values(errorMessages).some(Boolean)}
          onClick={onSubmit}
        >
          {t("profileSocialEdit.save")}
        </button>
      </div>
    </div>
  );
};

export default ProfileSocialEdit;
