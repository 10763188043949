// React
import { useContext } from "react";

// Icons
import azFlag from "../assets/images/icons/az_flag.svg";
import enFlag from "../assets/images/icons/en_flag.svg";
import ruFlag from "../assets/images/icons/ru_flag.svg";

// i18next translation
import { MainContext } from "../utils/MainContext";

const MobileLanguageSelect = () => {
  // Global States
  const { langModalVivible, setLangModalVisible, changeLanguage } =
    useContext(MainContext);

  return (
    <div className="mobileLanguageSelect">
      <div
        className={langModalVivible ? "mobileOverlay visible" : "mobileOverlay"}
        onClick={() => setLangModalVisible(false)}
      ></div>
      <div
        className={
          langModalVivible ? "languageSelect acitve" : "languageSelect"
        }
      >
        <div className="line"></div>
        <ul className={langModalVivible ? "langArea active" : "langArea"}>
          <li className="seperator"></li>
          <li
            className="langs"
            id="az-AZ"
            onClick={() => {
              changeLanguage("az");
              setLangModalVisible(false);
            }}
          >
            <img src={azFlag} alt="az" />
            Azərbaycanca
          </li>
          <li
            className="langs"
            id="en-US"
            onClick={() => {
              changeLanguage("en");
              setLangModalVisible(false);
            }}
          >
            <img src={enFlag} alt="en" />
            English
          </li>
          <li
            className="langs"
            id="ru-RU"
            onClick={() => {
              changeLanguage("ru");
              setLangModalVisible(false);
            }}
          >
            <img src={ruFlag} alt="ru" />
            Русский
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileLanguageSelect;
